import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Avatar,
  Divider,
  IconButton,
  TextField,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { db, auth } from "../api/firebase"; // Firebaseの設定をインポート
import { doc, getDoc, updateDoc, deleteDoc } from "firebase/firestore"; // FirestoreのAPI
import moment from "moment"; // 日時の表示を「◯時間前」「◯分前」「◯日前」にするためにmoment.jsを使用
import EditIcon from "@mui/icons-material/Edit"; // 編集アイコン
import DeleteIcon from "@mui/icons-material/Delete"; // 削除アイコン
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"; // 画像上移動アイコン
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"; // 画像下移動アイコン
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css"; // スタイルもインポート
import reactStringReplace from "react-string-replace";

// 画像を100KB以下にダウンサイズする関数
const resizeImage = (file) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const reader = new FileReader();

    reader.onload = (e) => {
      img.src = e.target.result;
    };

    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // 最大幅、高さを指定（例えば、幅500px, 高さ500px）
      const maxWidth = 500;
      const maxHeight = 500;

      // リサイズ比率を計算
      const ratio = Math.min(maxWidth / img.width, maxHeight / img.height);
      const width = img.width * ratio;
      const height = img.height * ratio;

      canvas.width = width;
      canvas.height = height;

      // 画像を描画してリサイズ
      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob(
        (blob) => {
          if (blob) {
            resolve(blob); // リサイズされた画像を返す
          } else {
            reject(new Error("Blob creation failed"));
          }
        },
        "image/jpeg",
        0.7
      ); // 画像形式や圧縮率を設定
    };

    img.onerror = reject;
    reader.readAsDataURL(file);
  });
};

const KomebuNewsDetailDialog = ({ open, onClose, news, handleUpdateNews }) => {
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false); // 管理者かどうかを判定するステート
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false); // 削除確認ダイアログ
  const [openEditDialog, setOpenEditDialog] = useState(false); // 編集ダイアログ
  const [newTitle, setNewTitle] = useState(""); // 新しいタイトル
  const [newContent, setNewContent] = useState(""); // 新しいコメント
  const [newImages, setNewImages] = useState([]); // 新しい画像
  const [timeAgo, setTimeAgo] = useState("不明"); // 時間の表示
  const [isLightboxOpen, setIsLightboxOpen] = useState(false); // Lightboxが開いているかどうか
  const [lightboxIndex, setLightboxIndex] = useState(0); // 現在表示されている画像のインデックス

  // newsが存在する場合のみ実行
  useEffect(() => {
    if (news) {
      setNewTitle(news.title || "");
      setNewContent(news.content || "");
      setNewImages(news.images || []);
      if (news.createdAt) {
        setTimeAgo(moment(news.createdAt.toDate()).fromNow());
      }
    }
  }, [news]);

  // console.log("news =>",news)
  // 現在のユーザーの情報を取得
  useEffect(() => {
    const fetchUser = async () => {
      if (news?.createdBy) {
        try {
          const userDocRef = doc(db, "users", news.createdBy);
          const userDocSnap = await getDoc(userDocRef);
  
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            setUser(userData); // 取得したユーザーデータをセット
          } else {
            console.warn("User document not found:", news.createdBy);
            setUser(null);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };
  
    fetchUser();
  }, [news]); // newsが変更されたら再取得
  
  const handleDelete = async () => {
    try {
      await deleteDoc(doc(db, "komebuNews", news.id)); // Firestoreから削除
      setOpenConfirmDialog(false); // 削除確認ダイアログを閉じる
      onClose(); // ダイアログを閉じる
    } catch (error) {
      console.error("Error deleting news:", error);
    }
  };

  // Lightboxを開いたときの処理
  const handleImageClick = (index) => {
    // インデックスと画像URLをログに出力
    console.log("Image clicked, index:", index);
    console.log("Image URL:", newImages[index].url || newImages[index].preview);

    setLightboxIndex(index);
    setIsLightboxOpen(true);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(true); // 削除確認ダイアログを開く
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false); // 削除確認ダイアログを閉じる
  };

  const handleEdit = () => {
    // 編集ボタンが押されたときにタイトルとコメントをセット
    setNewTitle(news.title || "");
    setNewContent(news.content || "");
    setOpenEditDialog(true); // 編集ダイアログを開く
  };

  const handleImageChange = async (e) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files);
      const resizedImages = [];

      // 画像リサイズの処理
      for (let i = 0; i < filesArray.length; i++) {
        const file = filesArray[i];
        try {
          // ダウンサイズして画像を追加
          const resizedImage = await resizeImage(file);

          // リサイズされた画像を配列に追加
          resizedImages.push({
            file: resizedImage,
            preview: URL.createObjectURL(resizedImage),
            order: resizedImages.length + i, // 画像の順番を設定
          });

          // デバッグ用: リサイズされた画像を確認
          console.log("Resized Image:", resizedImage);
        } catch (error) {
          console.error("Error resizing image:", error);
          alert("画像のサイズが大きすぎます。圧縮できませんでした。");
        }
      }

      // 画像がリサイズされていれば追加
      if (resizedImages.length > 0) {
        console.log("Adding resized images to newImages:", resizedImages);
        setNewImages((prevImages) => prevImages.concat(resizedImages)); // 画像の追加
      } else {
        console.warn(
          "No images to add. Please check the resizeImage function."
        );
      }
    }
  };

  const handleMoveImage = (index, direction) => {
    const newImagesList = [...newImages];
    const [movedImage] = newImagesList.splice(index, 1); // 移動させた画像を取り出す
    newImagesList.splice(index + direction, 0, movedImage); // 新しい位置に追加

    // 移動後、全画像のorderを更新
    newImagesList.forEach((image, idx) => {
      image.order = idx; // 順番を設定
    });

    setNewImages(newImagesList); // 更新した順番の画像リストをセット

    console.log("Updated Images after move:", newImagesList); // 変更された画像の順番をコンソールに出力
  };

  // 画像削除処理
  const handleImageDelete = (index) => {
    const newImagesList = [...newImages];
    newImagesList.splice(index, 1); // 指定したインデックスの画像を削除

    // 削除後、順番を再調整
    newImagesList.forEach((image, idx) => {
      image.order = idx; // 順番を再設定
    });

    setNewImages(newImagesList); // 更新した順番の画像リストをセット
  };

  const handleEditSave = async () => {
    // 必須フィールドが空でないかチェック
    if (!newTitle || !newContent) {
      alert("タイトルとコメントは必須です！");
      return;
    }

    // newImages に含まれる画像を正しくマッピング
    const updatedImages = newImages
      .map((image) => {
        // image.preview や image.url が undefined の場合に処理
        if (image.url) {
          return { url: image.url, order: image.order }; // URLとorderをセット
        } else if (image.preview) {
          return { url: image.preview, order: image.order }; // プレビューとorderをセット
        } else {
          console.warn("Invalid image data found:", image);
          return null; // 無効な画像データは無視
        }
      })
      .filter((image) => image !== null); // null や undefined の画像を除外

    console.log("Updated images before saving:", updatedImages); // 更新される画像URLとorderの確認

    try {
      const newsDocRef = doc(db, "komebuNews", news.id);

      // 更新処理を行う
      await updateDoc(newsDocRef, {
        title: newTitle,
        content: newContent,
        images: updatedImages, // 画像の順番とURLを保存
      });

      // 親コンポーネントに更新されたnewsを渡して更新
      handleUpdateNews({
        id: news.id,
        title: newTitle,
        content: newContent,
        images: updatedImages, // 更新後の画像データを渡す
      });

      onClose(); // ダイアログを閉じる
      setOpenEditDialog(false); // 編集ダイアログを閉じる
    } catch (error) {
      console.error("Error updating news:", error);
    }
  };

  function linkifyText(text) {
    if (!text) return null; // textがundefinedやnullの場合はnullを返す
    const urlRegex = /(\bhttps?:\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

    return text.split(urlRegex).map((part, index) => {
      if (part.match(urlRegex)) {
        return (
          <a
            key={index}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: "blue",
              wordWrap: "break-word", // URLが途中で折り返されるように
              wordBreak: "break-word", // URL部分での強制的な改行
              overflowWrap: "break-word", // 余分な文字列がはみ出ないように
            }}
          >
            {part}
          </a>
        );
      }

      // 改行文字を <br /> に変換
      return part.split("\n").map((line, i) => (
        <React.Fragment key={`${index}-${i}`}>
          {line}
          <br />
        </React.Fragment>
      ));
    });
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogContent sx={{ paddingTop: 2 }}>
        {/* タイトル表示 */}
        {news && (
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              marginBottom: 2,
              maxWidth: "100%", // 親コンポーネントに合わせて最大幅を設定
              overflow: "hidden", // はみ出し防止
              wordWrap: "break-word", // 長い単語を折り返して表示
              whiteSpace: "normal", // テキストを折り返し
            }}
          >
            {news.title}
          </Typography>
        )}

        {/* 投稿者情報（名前と日時を2段で表示） */}
        {user && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: 2,
            }}
          >
            <Avatar src={user.avatar} alt={user.name} sx={{ marginRight: 2 }} />
            <div>
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                {user.lastName} {user.firstName}
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontSize: "0.85rem", color: "blue" }}
              >
                {timeAgo}
              </Typography>
            </div>
          </div>
        )}

        <Divider sx={{ marginBottom: 2 }} />

        {/* コメント表示 */}
        {news && (
          <Typography
            variant="body1"
            sx={{
              my: 2,
              lineHeight: 1.5,
              maxWidth: "100%", // 親コンポーネントに合わせて最大幅を設定
              overflow: "hidden", // はみ出し防止
              wordWrap: "break-word", // 長い単語やURLを折り返し
              whiteSpace: "normal", // テキストを折り返し
              overflowWrap: "break-word", // URL部分の折り返しを強制
              wordBreak: "break-word", // 長い単語（特にURL）を途中で折り返す
            }}
          >
            {linkifyText(news.content)}
          </Typography>
        )}

        <Divider sx={{ marginBottom: 2 }} />

        {/* 画像 */}
        <div style={{ display: "flex", flexWrap: "wrap", gap: 10 }}>
          {newImages.map((image, index) => (
            <img
              key={index}
              src={image.url || image.preview}
              alt={`News ${index + 1}`}
              style={{
                width: "120px",
                height: "80px",
                objectFit: "cover",
                cursor: "pointer", // クリックできるようにカーソルを変更
              }}
              onClick={() => handleImageClick(index)} // クリックした画像のインデックスを渡す
            />
          ))}
        </div>
        {/* Lightbox表示 */}
        {isLightboxOpen && (
          <Lightbox
            open={isLightboxOpen}
            index={lightboxIndex}
            close={() => setIsLightboxOpen(false)} // Lightboxを閉じる
            slides={newImages.map((image) => ({ src: image.url }))} // 画像URLをスライドとして渡す
          />
        )}
      </DialogContent>

      {/* 編集と削除ボタン（管理者のみ表示） */}
      {isAdmin && (
        <DialogActions
          sx={{ padding: 2, display: "flex", justifyContent: "flex-start" }}
        >
          <IconButton onClick={handleEdit} color="default">
            <EditIcon />
          </IconButton>
          <IconButton onClick={handleConfirmDelete} color="default">
            <DeleteIcon />
          </IconButton>
        </DialogActions>
      )}

      {/* 削除確認ダイアログ */}
      <Dialog
        open={openConfirmDialog}
        onClose={handleCancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"本当に削除しますか？"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="secondary">
            キャンセル
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            削除
          </Button>
        </DialogActions>
      </Dialog>

      {/* 編集ダイアログ */}
      <Dialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>お知らせ編集</DialogTitle>
        <DialogContent>
          <TextField
            label="タイトル"
            fullWidth
            value={newTitle}
            onChange={(e) => setNewTitle(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="コメント"
            fullWidth
            multiline
            rows={4}
            value={newContent}
            onChange={(e) => setNewContent(e.target.value)}
            sx={{ marginBottom: 2 }}
          />

          {/* 画像編集部分 */}
          <div>
            <Button
              variant="contained"
              component="label"
              startIcon={<PhotoCamera />}
              sx={{ mt: 2, mb: 2 }}
            >
              画像を追加
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={handleImageChange} // 画像を追加する処理
                multiple
              />
            </Button>

            {newImages.map((image, index) => (
              <ListItem
                key={index}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <ListItemIcon>
                  <img
                    src={image.url || image.preview} // プレビュー画像を表示
                    alt={`preview-${index}`}
                    style={{ width: 100, height: 100, objectFit: "cover" }}
                  />
                </ListItemIcon>
                <IconButton
                  onClick={() => handleMoveImage(index, -1)}
                  disabled={index === 0}
                >
                  <ArrowUpwardIcon />
                </IconButton>
                <IconButton
                  onClick={() => handleMoveImage(index, 1)}
                  disabled={index === newImages.length - 1}
                >
                  <ArrowDownwardIcon />
                </IconButton>
                <ListItemText primary={`画像 ${index + 1}`} />
                <IconButton onClick={() => handleImageDelete(index)}>
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            ))}
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setOpenEditDialog(false)} color="secondary">
            キャンセル
          </Button>
          <Button onClick={handleEditSave} color="primary">
            保存
          </Button>
        </DialogActions>
      </Dialog>

      <DialogActions sx={{ padding: 2 }}>
        <Button onClick={onClose} color="secondary">
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default KomebuNewsDetailDialog;
