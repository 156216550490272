import React, { useContext, useEffect, useState } from "react";

// -----臨時ログアウト----- //
import { auth, db } from "../api/firebase";
import { getDoc, doc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import NotificationFeed from "./NotificationFeed";
import { useAuth } from "../context/auth";
import { memo } from "react";
import StreamChatOnHome from "./StreamChatOnHome";
import NotificationOfHomeTop from "./NotificationOfHomeTop";
import Feeds from "./Feeds";
import DisplayNeighbors from "./DisplayNeighbors";
import { TextNotification } from "./TextNotification";
import MusicPlayer from "./MusicPlayer";
import AudioPlayer from "./AudioPlayer";

// -----臨時ログアウト----- //

const MainContent = () => {
  const { user } = useAuth();

  // ユーザ情報取得
  const [userInfo, setUserInfo] = useState("");
  useEffect(() => {
    const getInitUser = () => {
      getDoc(doc(db, "users", auth.currentUser.uid)).then((doc) => {
        setUserInfo({ id: doc.id, ...doc.data() });
      });
    };
    user && getInitUser();
  }, []);

  // 表示・非表示
  const [open, setOpen] = useState(true);
  const [form, setForm] = useState("forFirstLoginUser");
  const handleEdit = (e, id) => {
    setOpen(true);
    setForm(e);
  };

  const navigate = useNavigate();

  return (
    <div className="flex h-full lg:h-[85vh]  bg-back">
      {/* <NotificationStreamChatMessage user={userInfo} /> */}
      {/* 左ウィジェット */}
      <div className=" hidden  xl:block w-80  text-3xl lg:overflow-y-auto scrollbar-hide">
        {/* <MusicPlayer /> */}
      </div>

      {/* 中央コンテンツ */}
      <div className="flex flex-col space-y-3  xl:mx-4 flex-1 max-w-3xl lg:overflow-y-auto scrollbar-hide text-3xl ">
        <div className="font-bold text-2xl">お知らせ</div>
        <NotificationOfHomeTop />

        {userInfo?.isVillager && <AudioPlayer />}

        <div className="font-bold text-2xl">イベント</div>
        <NotificationFeed />
        {/* <NearyBirthDayVillager /> */}
        <div className="font-bold text-2xl">Feed</div>
        <Feeds />
      </div>

      {/* 右ウィジェット */}
      <div className=" hidden  lg:block w-80 flex-none  text-3xl lg:overflow-y-auto scrollbar-hide p-2">
        {/* みんなの掲示板 */}

        {userInfo.isVillager && (<>
        
        <div className="flex flex-col mb-2">
          <div className="w-full">
            <div className="flex text-xl font-bold my-2 ">みんなの掲示板</div>
            {userInfo && <StreamChatOnHome user={userInfo} />}
          </div>
        </div>
        </>)}
        {/* <Test /> */}
        {/* <StreamChatEveryOne /> */}

        {/* 近隣さん */}
        <DisplayNeighbors />

        {/* <Widgets />
        <Widgets />
        <Widgets />
        <Widgets />
        <Widgets />
        <Widgets /> */}
      </div>
    </div>
  );
};

export default memo(MainContent);
