import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
  updateDoc,
  arrayUnion,
  getDoc,
} from "firebase/firestore";
import { auth, db } from "../api/firebase";
import { useDocumentData } from "react-firebase-hooks/firestore";
import SelectUsers from "./SelectUsers";
import GetUserInfo from "./GetUserInfo";
import { StreamChat } from "stream-chat";

const AddBaseChiefDialog = ({ open, setOpen, requestId,updateBaseChiefs }) => {
  const [product, setProduct] = useState({
    chief: [],
    error: "",
    loading: false,
  });

  const { chief, error, loading } = product;

  const handleChange = (e, value) => {
    if (!chief.includes(value.id)) {
      setProduct({
        ...product,
        chief: [...chief, value.id],
      });
    }
  };

  const [adminUser] = useDocumentData(doc(db, "users", auth.currentUser.uid));

  const setRequestDataShop = async (e) => {
    e.preventDefault();
    setProduct({ ...product, error: "", loading: true });

    try {
      const docRef = doc(db, "base", requestId);
      const baseDocSnap = await getDoc(docRef);
      let currentChiefs = [];

      if (baseDocSnap.exists()) {
        const data = baseDocSnap.data();
        if (Array.isArray(data.chief)) {
          currentChiefs = data.chief;
        } else if (typeof data.chief === "string" && data.chief.trim() !== "") {
          currentChiefs = [data.chief];
        }
      }

      const updatedChiefs = [
        ...new Set([...currentChiefs, ...chief].filter((id) => id && id.trim() !== "")),
      ];

      await updateDoc(docRef, {
        chief: updatedChiefs,
        lastUpdatedAt: serverTimestamp(),
      });

      for (let leader of chief) {
        await setDoc(doc(db, "base", requestId, "members", leader), {
          createdAt: serverTimestamp(),
          isApplication: false,
          isMember: true,
          isChief: true,
          uid: leader,
        });

        await setDoc(doc(db, "users", leader, "affiliationBase", requestId), {
          baseId: requestId,
          createdAt: serverTimestamp(),
          type: "chief",
          registered: auth.currentUser.uid,
        });
      }

      // チャットに追加
      const apiKey = process.env.REACT_APP_STREAM_API_KEY;
      const client = StreamChat.getInstance(apiKey, { timeout: 6000 });

      const token = await fetch(process.env.REACT_APP_STREAM_GET_TOKEN_URL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ input: adminUser.uid }),
      }).then((r) => r.json());

      await client.connectUser(
        { id: adminUser.uid, name: `${adminUser.lastName} ${adminUser.firstName}`, image: adminUser.avatar },
        token
      );

      const channel = client.channel("messaging", `baseChat-${requestId}`);
      await channel.addMembers(chief);

      setProduct({ chief: [], error: "", loading: false });
      setOpen(false);
      updateBaseChiefs()
    } catch (err) {
      console.error(err);
      await addDoc(collection(db, "error"), {
        createdAt: serverTimestamp(),
        error: { message: err.message, stack: err.stack },
      });

      setProduct({ ...product, error: err.message, loading: false });
    }
  };

  const removeChief = (leaderId) => {
    setProduct({ ...product, chief: chief.filter((id) => id !== leaderId) });
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
      <DialogTitle>村長を追加</DialogTitle>
      <DialogContent>
        <SelectUsers handleChange={handleChange} />

        {/* 選択した村長リスト */}
        <div className="my-3">
          {chief.map((leaderId) => (
            <div key={leaderId} className="flex items-center space-x-2 p-2 border rounded-md my-1">
              <GetUserInfo uid={leaderId} />
              <button
                type="button"
                className="bg-red-500 text-white px-2 py-1 rounded"
                onClick={() => removeChief(leaderId)}
              >
                削除
              </button>
            </div>
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="secondary">
          キャンセル
        </Button>
        <Button
          onClick={setRequestDataShop}
          color="primary"
          variant="contained"
          disabled={loading}
        >
          {loading ? "送信中..." : "登録"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddBaseChiefDialog;
