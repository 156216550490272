import React, { useState, useEffect } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  Box,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import MapIcon from "@mui/icons-material/Map";
import GroupIcon from "@mui/icons-material/Group";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import ListIcon from "@mui/icons-material/List";
import PersonIcon from "@mui/icons-material/Person";
import AddIcon from "@mui/icons-material/Add";
import { db, auth } from "../api/firebase";
import { doc, getDoc } from "firebase/firestore";
import MapComponent from "./MapComponent";
import FarmerRegistrationForm from "./FarmerRegistrationForm"; // 農家登録フォームをインポート
import GroupCreationDialog from "./GroupCreationDialog";
import KomebuGroupList from "./KomebuGroupList";
import KomebuJoinedGroups from "./KomebuJoinedGroups";
import FarmerListByRegion from "./FarmerListByRegion";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import KomebuNewsList from "./KomebuNewsList";

const KomebuDesktop = () => {
  const [selectedMenu, setSelectedMenu] = useState("お知らせ");
  const [user, setUser] = useState(null);
  const [isDialogOpen, setDialogOpen] = useState(false); // グループ作成ダイアログ
  const [isCompletionDialogOpen, setCompletionDialogOpen] = useState(false); // 作成完了ダイアログ
  const [isFarmerDialogOpen, setFarmerDialogOpen] = useState(false); // 農家登録ダイアログ

  // Firebaseからユーザーデータを取得
  useEffect(() => {
    const fetchUserData = async () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        const userDocRef = doc(db, "users", currentUser.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          setUser(userDocSnap.data());
        } else {
          console.error("User document not found");
        }
      }
    };

    fetchUserData();
  }, []);

  // メニュー選択時のハンドラー
  const handleMenuClick = (menuName) => {
    setSelectedMenu(menuName);
  };

  // ダイアログの開閉ハンドラー
  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = () => setDialogOpen(false);

  // 作成完了ダイアログの開閉ハンドラー
  const handleCompletionDialogOpen = () => setCompletionDialogOpen(true);
  const handleCompletionDialogClose = () => setCompletionDialogOpen(false);

  // 農家登録ダイアログの開閉ハンドラー
  const handleFarmerDialogOpen = () => setFarmerDialogOpen(true);
  const handleFarmerDialogClose = () => setFarmerDialogOpen(false);

  // グループ作成成功時のハンドラー
  const handleGroupCreated = (isCreated) => {
    if (isCreated) {
      handleCompletionDialogOpen(); // 成功時のみ完了ダイアログを開く
    }
    handleDialogClose(); // 常にグループ作成ダイアログを閉じる
  };

  // 右側コンテンツの表示内容を切り替え
  const renderContent = () => {
    switch (selectedMenu) {
      case "ダッシュボード":
        return <Typography variant="h5">ダッシュボードの内容です</Typography>;
      case "マップ":
        return <MapComponent />;
      case "マップ サブメニュー 1":
        return (
          <Typography variant="h5">
            田んぼ登録はこちらから行ってください。
          </Typography>
        );
      case "マップ サブメニュー 2":
        return <FarmerListByRegion />;
      case "グループ":
        return <Typography variant="h5">グループの内容を表示します</Typography>;
      case "グループ 一覧":
        return <KomebuGroupList user={user} />;
      case "参加グループ":
        return <KomebuJoinedGroups user={user} />;
      case "お知らせ":
        return <KomebuNewsList user={user} />;
      default:
        return <Typography variant="h5">コメ部へようこそ</Typography>;
    }
  };

  return (
    <>
      <Paper
        elevation={10}
        sx={{
          display: "flex",
          minHeight: "calc(100vh - 134px)",
          border: "1px solid #e0e0e0",
          borderRadius: 2,
          boxSizing: "border-box",
        }}
      >
        {/* 左側の固定メニュー */}
        <Box
          sx={{
            width: 240,
            p: 2,
            flexShrink: 0,
            boxSizing: "border-box",
            borderRight: "1px solid #e0e0e0",
          }}
        >
          <List>
          {user?.isAdmin && (
              <ListItem
                button
                selected={selectedMenu === "お知らせ"}
                onClick={() => handleMenuClick("お知らせ")}
              >
                <ListItemIcon>
                  <TipsAndUpdatesIcon />
                </ListItemIcon>
                <ListItemText primary="お知らせ" />
              </ListItem>
            )}


            {/* <ListItem
              button
              selected={selectedMenu === "ダッシュボード"}
              onClick={() => handleMenuClick("ダッシュボード")}
            >
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="ダッシュボード" />
            </ListItem> */}

            <ListItem
              button
              selected={selectedMenu === "マップ"}
              onClick={() => handleMenuClick("マップ")}
            >
              <ListItemIcon>
                <MapIcon />
              </ListItemIcon>
              <ListItemText primary="マップ" />
            </ListItem>
            <List component="div" disablePadding>
              {user?.isAdmin && (
                <ListItem
                  button
                  selected={selectedMenu === "マップ サブメニュー 1"}
                  sx={{ pl: 4 }}
                  onClick={() => handleFarmerDialogOpen()} // 田んぼ登録ダイアログを開く
                >
                  <ListItemIcon>
                    <SubdirectoryArrowRightIcon />
                  </ListItemIcon>
                  <ListItemText primary="田んぼ登録" />
                </ListItem>
              )}
              {user?.isAdmin && (
                <ListItem
                  button
                  selected={selectedMenu === "マップ サブメニュー 2"}
                  sx={{ pl: 4 }}
                  onClick={() => handleMenuClick("マップ サブメニュー 2")}
                >
                  <ListItemIcon>
                    <SubdirectoryArrowRightIcon />
                  </ListItemIcon>
                  <ListItemText primary="登録農地一覧" />
                </ListItem>
              )}
            </List>

            <ListItem
              button
              selected={selectedMenu === "グループ"}
              onClick={() => handleMenuClick("グループ")}
            >
              <ListItemIcon>
                <GroupIcon />
              </ListItemIcon>
              <ListItemText primary="グループ" />
            </ListItem>
            <List component="div" disablePadding>
              <ListItem
                button
                selected={selectedMenu === "グループ 一覧"}
                sx={{ pl: 4 }}
                onClick={() => handleMenuClick("グループ 一覧")}
              >
                <ListItemIcon>
                  <ListIcon />
                </ListItemIcon>
                <ListItemText primary="グループ一覧" />
              </ListItem>
              <ListItem
                button
                selected={selectedMenu === "参加グループ"}
                sx={{ pl: 4 }}
                onClick={() => handleMenuClick("参加グループ")}
              >
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary="参加グループ" />
              </ListItem>
              {user?.isAdmin && (
                <ListItem
                  button
                  selected={selectedMenu === "グループ作成"}
                  sx={{ pl: 4 }}
                  onClick={handleDialogOpen}
                >
                  <ListItemIcon>
                    <AddIcon />
                  </ListItemIcon>
                  <ListItemText primary="グループ作成" />
                </ListItem>
              )}
            </List>
          </List>
        </Box>

        {/* 右側の可変コンテンツ */}
        <Box sx={{ flexGrow: 1, p: 3 }}>{renderContent()}</Box>
      </Paper>

      {/* グループ作成ダイアログ */}
      <Dialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        maxWidth="sm"
        fullWidth
      >
        <GroupCreationDialog
          onGroupCreated={(isCreated) => handleGroupCreated(isCreated)}
          user={user}
          onClose={handleDialogClose}
        />
      </Dialog>

      {/* 作成完了ダイアログ */}
      <Dialog
        open={isCompletionDialogOpen}
        onClose={handleCompletionDialogClose}
      >
        <DialogTitle>グループ作成完了</DialogTitle>
        <DialogContent>
          <Typography>グループが正常に作成されました。</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCompletionDialogClose}
            color="primary"
            variant="contained"
          >
            閉じる
          </Button>
        </DialogActions>
      </Dialog>

      {/* 農家登録ダイアログ */}
      <Dialog
        open={isFarmerDialogOpen}
        onClose={handleFarmerDialogClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>田んぼ登録</DialogTitle>
        <DialogContent>
          <FarmerRegistrationForm />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFarmerDialogClose} color="secondary">
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default KomebuDesktop;
