import React, { useEffect, useState } from "react";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { ja } from "date-fns/locale";
import Vimeo from "@u-wave/react-vimeo";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/auth";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../api/firebase";

// YouTubeの動画IDを抽出する関数
const extractYouTubeId = (url) => {
  const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = url.match(regex);
  return match ? match[1] : null;
};



// Vimeo のサムネイル取得関数
const fetchVimeoThumbnail = async (videoId) => {
  try {
    const response = await fetch(`https://vimeo.com/api/v2/video/${videoId}.json`);
    const data = await response.json();
    return data[0]?.thumbnail_large || "";
  } catch (error) {
    console.error("Error fetching Vimeo thumbnail:", error);
    return "";
  }
};


// 動画を表示するコンポーネント
const DisplayVideo = ({ movie }) => {
  const { user } = useAuth();

  const navigate = useNavigate();
  const handleMove = () => {
    navigate("/movie/" + movie?.id);
  };

  // 動画の公開範囲を表示する関数
  const displayMovieOpenRange = (text) => {
    switch (text) {
      case "forUsersOver":
        return "Users";
      case "Free":
        return "Free";
      case "forVillagers":
        return "Members";
      default:
        return null; // 必要に応じてデフォルト値を設定
    }
  };

  const [isUser, setIsUser] = useState(user ? true : false);
  const [userInfo, setUserInfo] = useState("");
  useEffect(() => {
    const initUserInfo = async () => {
      const docRef = doc(db, "users", user?.uid);
      const snapshot = await getDoc(docRef);
      if (snapshot.exists()) {
        const data = snapshot.data();
        setUserInfo({ id: snapshot.id, ...data });
      }
    };

    if (isUser) {
      initUserInfo();
    }
  }, [isUser, user?.uid]);

  const [isVillager, setIsVillager] = useState(false);
  useEffect(() => {
    const initIsVillager = () => {
      setIsVillager(userInfo?.isVillager);
    };

    if (userInfo) {
      initIsVillager();
    }
  }, [userInfo]);

  const [controleOk, setControleOK] = useState(false);
  useEffect(() => {
    // 動画の公開範囲に応じて制御フラグを設定
    if (
      displayMovieOpenRange(movie?.openRange) === "Free" ||
      (displayMovieOpenRange(movie?.openRange) === "Users" && isUser) ||
      (displayMovieOpenRange(movie?.openRange) === "Members" &&
        isUser &&
        userInfo?.isVillager)
    ) {
      setControleOK(true);
    }
  }, [isUser, userInfo?.isVillager]);


    const [thumbnail, setThumbnail] = useState("");

  useEffect(() => {
    if (movie.platform === "YouTube") {
      const videoId = extractYouTubeId(movie.url);
      if (videoId) {
        setThumbnail(`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`);
      }
    } else if (movie.platform === "Vimeo") {
      const videoId = movie.url.split("/").pop();
      fetchVimeoThumbnail(videoId).then(setThumbnail);
    }
  }, [movie]);

  return (
    <>
      <div>

           {/* 動画サムネイル */}
      {thumbnail ? (
        <img
          src={thumbnail}
          alt={movie.title}
          className="w-full h-auto rounded-lg shadow-lg"
        />
      ) : (
        <div className="w-full h-48 bg-gray-200 flex items-center justify-center text-gray-500">
          No Thumbnail Available
        </div>
      )}


        {/* {movie.platform === "Vimeo" ? (
          <Vimeo
            className="hover:cursor-pointer"
            key={Math.random()}
            video={movie.url}
            responsive
            controls={controleOk}
          />
        ) : movie.platform === "YouTube" ? (

          <iframe
            className="hover:cursor-pointer"
            key={Math.random()}
            src={`https://www.youtube.com/embed/${extractYouTubeId(movie.url)}`}
            title={movie.title}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            style={{
              position: "relative", // absolute から relative に変更
              width: "100%", // 幅を100%に設定
              height: "auto", // 高さを自動に設定
              aspectRatio: "16/9", // アスペクト比を16:9に設定
              borderRadius: "4px",
            }}
          ></iframe>
        ) : (
          <div>Invalid video platform</div>
        )} */}

        {/* タイトル */}
        <div className="hover:cursor-pointer" onClick={handleMove}>
          {/* タイトル */}
          <div className="grid col-span-1 break-words whitespace-pre-line line-clamp-2 font-bold text-xl">
            {movie.title}
          </div>
          {/* 権限による色 */}
          {displayMovieOpenRange(movie?.openRange) === "Free" && (
            <div className="border border-red-500 bg-red-200 bg-opacity-75 rounded-xl px-2 py-1 w-fit">
              <div className="text-xs text-red-700">
                {displayMovieOpenRange(movie?.openRange)}
              </div>
            </div>
          )}
          {displayMovieOpenRange(movie?.openRange) === "Users" && (
            <div className="border border-blue-500 bg-blue-200 bg-opacity-75 rounded-xl px-2 py-1 w-fit">
              <div className="text-xs text-blue-700">
                {displayMovieOpenRange(movie?.openRange)}
              </div>
            </div>
          )}
          {displayMovieOpenRange(movie?.openRange) === "Members" && (
            <div className="border border-green-500 bg-green-200 bg-opacity-75 rounded-xl px-2 py-1 w-fit">
              <div className="text-xs text-green-700">
                {displayMovieOpenRange(movie?.openRange)}
              </div>
            </div>
          )}
          {/* Xか月前 */}
          <div className="flex justify-items-end text-gray-500 text-xs">
            {formatDistanceToNow(movie?.createdAt.toDate(), {
              locale: ja,
            })}
            前
          </div>
        </div>
      </div>
    </>
  );
};

export default DisplayVideo;
