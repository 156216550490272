import React, { useState, useEffect } from "react";
import {
  BottomNavigation,
  BottomNavigationAction,
  Menu,
  MenuItem,
  Typography,
  Box,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import NotificationsIcon from "@mui/icons-material/Notifications"; // お知らせアイコン
import MapIcon from "@mui/icons-material/Map";
import GroupIcon from "@mui/icons-material/Group";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import { doc, getDoc } from "firebase/firestore";
import { db, auth } from "../api/firebase";
import { useNavigate } from "react-router-dom";
import KomebuNewsList from "./KomebuNewsList";
import MapComponent from "./MapComponent";
import KomebuGroupList from "./KomebuGroupList";
import KomebuJoinedGroups from "./KomebuJoinedGroups";
import FarmerListByRegion from "./FarmerListByRegion";

const KomebuMobile = () => {
  const [selectedTab, setSelectedTab] = useState(1); // 初回表示は「お知らせ」
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  // グループメニュー管理
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedGroupType, setSelectedGroupType] = useState("グループ一覧");

  useEffect(() => {
    const fetchUserData = async () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        const userDocRef = doc(db, "users", currentUser.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          setUser(userDocSnap.data());
        } else {
          console.error("User document not found");
        }
      }
    };

    fetchUserData();
  }, []);

  // グループメニューの開閉
  const handleGroupClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseGroupMenu = (groupType) => {
    setAnchorEl(null);
    if (groupType) {
      setSelectedGroupType(groupType);
      setSelectedTab(3); // グループの選択後、タブをグループに変更
    }
  };

  // タブの表示内容を切り替え
  const renderContent = () => {
    switch (selectedTab) {
      case 1:
        return <KomebuNewsList user={user} />;
      case 2:
        return <MapComponent />;
      case 3:
        return selectedGroupType === "グループ一覧" ? (
          <KomebuGroupList user={user} />
        ) : (
          <KomebuJoinedGroups user={user} />
        );
      case 4:
        return <FarmerListByRegion user={user} />;
      default:
        return null;
    }
  };

  // タブ変更時の動作
  const handleTabChange = (event, newValue) => {
    if (newValue === 0) {
      navigate("/home"); // HOMEタブクリック時のみ遷移
    } else if (newValue === 3) {
      setAnchorEl(event.currentTarget); // グループメニューを開く
    } else {
      setSelectedTab(newValue); // その他のタブは通常切り替え
    }
  };

  return (
    <div>
      {/* メインコンテンツ */}
      <main style={{  paddingBottom: "56px" }}>
        <Box>{renderContent()}</Box>
      </main>
      {/* BottomNavigation */}
      <BottomNavigation
        value={selectedTab}
        onChange={handleTabChange}
        showLabels
        style={{ position: "fixed", bottom: 0, width: "100%", zIndex: 1000 }}
      >
        <BottomNavigationAction label="HOME" icon={<HomeIcon />} />
        <BottomNavigationAction label="お知らせ" icon={<NotificationsIcon />} />
        <BottomNavigationAction label="マップ" icon={<MapIcon />} />
        <BottomNavigationAction label="グループ" icon={<GroupIcon />} />
        {user?.isAdmin && (
          <BottomNavigationAction label="農地一覧" icon={<AgricultureIcon />} />
        )}
      </BottomNavigation>
      {/* グループの選択メニュー */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleCloseGroupMenu(null)}
      >
        <MenuItem onClick={() => handleCloseGroupMenu("グループ一覧")}>
          グループ一覧
        </MenuItem>
        <MenuItem onClick={() => handleCloseGroupMenu("参加グループ")}>
          参加グループ
        </MenuItem>
      </Menu>
    </div>
  );
};

export default KomebuMobile;
