import React, { useState } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../api/firebase";
import {
  TextField,
  Button,
  Typography,
  Box,
  CircularProgress,
  Paper,
} from "@mui/material";

const MasterMindTokenViewer = () => {
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [tokenData, setTokenData] = useState(null);
  const [error, setError] = useState("");

  const handleTokenSearch = async () => {
    setLoading(true);
    setTokenData(null);
    setError("");

    try {
      const tokensRef = collection(db, "masterMindTokens");
      const tokenQuery = query(tokensRef, where("token", "==", token));
      const querySnapshot = await getDocs(tokenQuery);

      if (querySnapshot.empty) {
        setError("トークンが見つかりませんでした。");
      } else {
        const fetchedData = querySnapshot.docs[0].data();
        setTokenData(fetchedData);
      }
    } catch (err) {
      console.error("Error fetching token data:", err);
      setError("トークン情報の取得中にエラーが発生しました。");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ maxWidth: 600, margin: "0 auto", mt: 4 }}>
      <Typography variant="h5" align="center" sx={{ mb: 2 }}>
        トークン情報確認
      </Typography>
      <TextField
        label="トークンを入力"
        variant="outlined"
        fullWidth
        value={token}
        onChange={(e) => setToken(e.target.value)}
        sx={{ mb: 2 }}
      />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleTokenSearch}
        disabled={loading || !token.trim()}
      >
        確認
      </Button>

      {loading && (
        <Box sx={{ textAlign: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      )}

      {error && (
        <Typography variant="body1" color="error" align="center" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}

      {tokenData && (
        <Paper sx={{ mt: 4, p: 2 }}>
          <Typography variant="body1">
            <strong>トークン:</strong> {tokenData.token}
          </Typography>
          <Typography variant="body1">
            <strong>作成日時:</strong>{" "}
            {tokenData.registeredAt?.toDate().toLocaleString() || "N/A"}
          </Typography>
          {/* 必要なフィールドを追加 */}
        </Paper>
      )}
    </Box>
  );
};

export default MasterMindTokenViewer;
