import React, { useState } from "react";
import { TextField } from "@mui/material";

import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  getDoc,
} from "firebase/firestore";
import { auth, db } from "../api/firebase";
import { useDocumentData } from "react-firebase-hooks/firestore";
import SelectUsers from "./SelectUsers";

import { StreamChat } from "stream-chat";
import GetUserInfo from "./GetUserInfo";

const AddBaseChief = ({ setOpen, requestId }) => {
  const [product, setProduct] = useState({
    chief: [],
    error: "",
    loading: false,
  });
  const { chief, error, loading } = product;

  // 値変更（複数選択）
  const handleChange = (e, value) => {
    if (!chief.includes(value.id)) {
      setProduct({
        ...product,
        chief: [...chief, value.id], // 新しいリーダーを配列に追加
      });
    }
  };

  const [adminUser] = useDocumentData(doc(db, "users", auth.currentUser.uid));

  // 登録処理
  // const setRequestDataShop = async (e) => {
  //   e.preventDefault();
  //   setProduct({
  //     ...product,
  //     error: "",
  //     loading: true,
  //   });

  //   try {
  //     // Firestore 更新（リーダーを配列で追加）
  //     const docRef = doc(db, "base", requestId);
  //     await updateDoc(docRef, {
  //       chief: arrayUnion(...chief), // 配列として追加
  //       lastUpdatedAt: serverTimestamp(),
  //     });

  //     // 各リーダーを members コレクションに追加
  //     for (let leader of chief) {
  //       await setDoc(doc(db, "base", requestId, "members", leader), {
  //         createdAt: serverTimestamp(),
  //         isApplication: false,
  //         isMember: true,
  //         isChief: true,
  //         uid: leader,
  //       });

  //       // users コレクションにも追加
  //       await addDoc(collection(db, "users", leader, "affiliationBase"), {
  //         baseId: requestId,
  //         createdAt: serverTimestamp(),
  //         type: "chief",
  //         registered: auth.currentUser.uid,
  //       });
  //     }

  //     // chatのメンバーにも加える
  //     const userToConnect = {
  //       id: adminUser.uid,
  //       name: adminUser.lastName + adminUser.firstName,
  //       image: adminUser.avatar,
  //       langage: "jp",
  //     };
  //     const apiKey = process.env.REACT_APP_STREAM_API_KEY;
  //     const client = StreamChat.getInstance(apiKey, { timeout: 6000 });

  //     const token = await fetch(process.env.REACT_APP_STREAM_GET_TOKEN_URL, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         input: adminUser.uid,
  //       }),
  //     }).then((r) => r.json());

  //     await client.connectUser(userToConnect, token);
  //     const channel = client.channel("messaging", "baseChat-" + requestId);

  //     await channel.addMembers(chief, {});

  //     setProduct({
  //       chief: [],
  //       error: "",
  //       loading: false,
  //     });

  //     setOpen(false);
  //   } catch (err) {
  //     console.log(err);
  //     await addDoc(collection(db, "error"), {
  //       createdAt: serverTimestamp(),
  //       error: {
  //         message: err.message,
  //         stack: err.stack,
  //       },
  //     });

  //     setProduct({
  //       ...product,
  //       error: err.message,
  //       loading: false,
  //     });
  //   }
  // };

  const setRequestDataShop = async (e) => {
    e.preventDefault();
    setProduct({
      ...product,
      error: "",
      loading: true,
    });

    try {
      const docRef = doc(db, "base", requestId);
      const baseDocSnap = await getDoc(docRef);

      let currentChiefs = [];

      // すでにデータがある場合
      if (baseDocSnap.exists()) {
        const data = baseDocSnap.data();

        if (Array.isArray(data.chief)) {
          // 配列の場合、そのまま使う
          currentChiefs = data.chief;
        } else if (typeof data.chief === "string") {
          // 文字列の場合、空白や "" なら無視、それ以外なら配列化
          if (data.chief.trim() !== "") {
            currentChiefs = [data.chief];
          }
        }
      }

      // 空白やnullを削除し、有効な値のみを保持
      const updatedChiefs = [
        ...new Set(
          [...currentChiefs, ...chief].filter((id) => id && id.trim() !== "")
        ),
      ];

      // Firestore 更新（配列で保存）
      await updateDoc(docRef, {
        chief: updatedChiefs,
        lastUpdatedAt: serverTimestamp(),
      });

      // 各リーダーを members コレクションに追加
      for (let leader of chief) {
        await setDoc(doc(db, "base", requestId, "members", leader), {
          createdAt: serverTimestamp(),
          isApplication: false,
          isMember: true,
          isChief: true,
          uid: leader,
        });

        // users コレクションにも追加
        await setDoc(doc(db, "users", leader, "affiliationBase", requestId), {
          baseId: requestId,
          createdAt: serverTimestamp(),
          type: "chief",
          registered: auth.currentUser.uid,
        });
      }

      // chatのメンバーにも加える
      const userToConnect = {
        id: adminUser.uid,
        name: adminUser.lastName + adminUser.firstName,
        image: adminUser.avatar,
        langage: "jp",
      };
      const apiKey = process.env.REACT_APP_STREAM_API_KEY;
      const client = StreamChat.getInstance(apiKey, { timeout: 6000 });

      const token = await fetch(process.env.REACT_APP_STREAM_GET_TOKEN_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          input: adminUser.uid,
        }),
      }).then((r) => r.json());

      await client.connectUser(userToConnect, token);
      const channel = client.channel("messaging", "baseChat-" + requestId);

      await channel.addMembers(chief, {});

      setProduct({
        chief: [],
        error: "",
        loading: false,
      });

      setOpen(false);
    } catch (err) {
      console.log(err);
      await addDoc(collection(db, "error"), {
        createdAt: serverTimestamp(),
        error: {
          message: err.message,
          stack: err.stack,
        },
      });

      setProduct({
        ...product,
        error: err.message,
        loading: false,
      });
    }
  };

  // chief をリストから削除する関数
  const removeChief = (leaderId) => {
    setProduct({
      ...product,
      chief: chief.filter((id) => id !== leaderId),
    });
  };

  return (
    <div>
      <div className="mb-3">拠点のリーダーを追加</div>
      <form onSubmit={setRequestDataShop}>
        <SelectUsers handleChange={handleChange} />

        {/* 選択したリーダーの表示 */}
        <div className="my-3">
          {chief.map((leaderId) => (
            <div key={leaderId} className="flex items-center space-x-2">
              <GetUserInfo userId={leaderId} />
              <button
                type="button"
                className="bg-red-500 text-white px-2 py-1 rounded"
                onClick={() => removeChief(leaderId)}
              >
                削除
              </button>
            </div>
          ))}
        </div>

        {/* 登録ボタン */}
        <button
          className="inline-block px-6 py-2.5 mt-2 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg"
          disabled={loading}
        >
          {loading ? "送信しています..." : "登録"}
        </button>
        {error && <div className="text-red-500 mt-2">{error}</div>}
      </form>
    </div>
  );
};

export default AddBaseChief;
