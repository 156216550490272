import React from "react";
import { useEffect, useState } from "react";

import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";

import Box from "@mui/material/Box";

import EditIcon from "@mui/icons-material/Edit";
import ModalForm from "./ModalForm";

import FormControlLabel from "@mui/material/FormControlLabel";
import RemoveIcon from "@mui/icons-material/Remove";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import {
  doc,
  getDoc,
  updateDoc,
  addDoc,
  serverTimestamp,
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
} from "firebase/firestore";
import { db, auth } from "../api/firebase";
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore";
import GetUserInfo from "./GetUserInfo";
import { useParams } from "react-router-dom";
import AddAddressFromPostCode from "./AddAddressFromPostCode";
import { Button } from "react-activity-feed";
import AddBaseChiefDialog from "./AddBaseChiefDialog";

const BaseSetting = ({ base, updateBaseChiefs }) => {
  const { id } = useParams();
  const [baseDoc] = useDocumentData(doc(db, "base", base.id));
  const [creator] = useDocumentData(doc(db, "users", auth.currentUser.uid));
  //   拠点のメンバー
  const [baseMember] = useCollectionData(
    collection(db, "base", base.id, "members")
  );

  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // ModalFormで使用
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState("");
  const handleEdit = (e) => {
    setOpen(true);
    setForm(e);
  };

  const [openChiefDialog, setOpenChiefDialog] = useState(false);

  //   権限変更関連handle
  const [d_loding, setD_loading] = useState(false);
  const publishChange = async (id, type) => {
    const pub = await getDoc(doc(db, "base", id));
    await updateDoc(doc(db, "base", id), {
      [type]: !pub.data()[type],
    });

    const arr = {
      doMakeEventsEveryOne: {
        target: "イベント作成権限",
        doMake: !pub.data()[type] ? "誰でも作成可" : "創設者のみ可",
      },
      useDummyGeoPoint: {
        target: "ダミーポイント",
        doMake: !pub.data()[type] ? "使用する" : "使用しない",
      },
      releaseAddress: {
        target: "マップ公開",
        doMake: !pub.data()[type] ? "公開する" : "公開しない",
      },
    };

    // グループのupdates作成・更新
    await addDoc(collection(db, "base", id, "updates"), {
      createdAt: serverTimestamp(),
      title: "設定変更",
      information:
        creator?.lastName +
        creator?.firstName +
        "さんが拠点[" +
        baseDoc?.title +
        "]の" +
        arr[type].target +
        "を[" +
        arr[type].doMake +
        "]に変更しました",
      creator: auth.currentUser.uid,
      creatorAvatar: creator?.avatar,
    });

    setD_loading(false);
    // setDataLoading(true);
    // window.location.reload();
  };
  const handleEventChange = async (e) => {
    setD_loading(true);
    await publishChange(e.target.value, e.target.name);
  };

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedChiefId, setSelectedChiefId] = useState(null);

  // 村長削除処理
  const handleDeleteChief = async () => {
    if (!selectedChiefId) return;

    try {
      const baseRef = doc(db, "base", base.id);
      const baseDoc = await getDoc(baseRef);

      if (baseDoc.exists()) {
        const baseData = baseDoc.data();
        let updatedChiefs;

        // `chief` が配列か文字列かを判定し、削除
        if (Array.isArray(baseData.chief)) {
          updatedChiefs = baseData.chief.filter((id) => id !== selectedChiefId);
        } else if (typeof baseData.chief === "string") {
          updatedChiefs =
            baseData.chief === selectedChiefId ? "" : baseData.chief;
        }

        // `base` コレクションの `chief` を更新
        await updateDoc(baseRef, {
          chief: updatedChiefs,
          updatedAt: serverTimestamp(),
          updator: auth.currentUser.uid,
          updateReason: "deleteBaseChief",
        });

        // `base/members/{chiefId}` の `isChief` を `false` に更新
        const memberRef = doc(db, "base", base.id, "members", selectedChiefId);
        await updateDoc(memberRef, {
          isChief: false,
          updatedAt: serverTimestamp(),
          updator: auth.currentUser.uid,
          updateReason: "deleteBaseChief",
        });

        // `users/{chiefId}/affiliationBase` 内の `baseId === base.id` のドキュメントを削除
        const affiliationQuery = query(
          collection(db, "users", selectedChiefId, "affiliationBase"),
          where("baseId", "==", base.id)
        );
        const affiliationDocs = await getDocs(affiliationQuery);
        affiliationDocs.forEach(async (docSnap) => {
          await deleteDoc(
            doc(db, "users", selectedChiefId, "affiliationBase", docSnap.id)
          );
        });

        // 🔥 村長リストを最新の状態に更新
        updateBaseChiefs();
      }
    } catch (error) {
      console.error("村長の削除に失敗しました:", error);
    } finally {
      setOpenDeleteDialog(false);
    }
  };

  return (
    <>
      <ModalForm
        form={form}
        setOpen={setOpen}
        open={open}
        target={"base"}
        requestId={id}
      />

      <div className="w-full my-5">
        <TabContext value={value}>
          {/* 左リスト */}
          {/* <Box sx={{ width: "20%" }}> */}
          <Box sx={{ width: "100%" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              // orientation="vertical"
            >
              <Tab label="基本情報" value="1" />
              <Tab label="村長" value="2" />
              <Tab label="作物" value="3" />
              <Tab label="土地" value="4" />
              <Tab label="設備" value="5" />
            </TabList>
          </Box>
          {/* 基本情報 */}
          <TabPanel sx={{ width: "100%", padding: "0" }} value="1">
            <div className="flex flex-col space-y-3">
              {/* タイトル */}
              <div className="flex flex-col spay1 border shadow-md hover:border-blue-500 hover:shadow-lg p-3 rounded-md cursor-pointer ">
                <div className="flex-none w-32 inline-block align-middle   ">
                  タイトル
                </div>
                <div className="flex">
                  <div className="w-full p-2 border rounded-md ">
                    {base?.title}
                  </div>
                  <div
                    className="border p-3 rounded-full h-fit hover:shadow-lg"
                    onClick={() => handleEdit("editBaseTitle")}
                  >
                    <EditIcon />
                  </div>
                </div>
              </div>

              {/* ご挨拶 */}
              <div className="flex flex-col spay1 border shadow-md hover:border-blue-500 hover:shadow-lg p-3 rounded-md cursor-pointer ">
                <div className="flex-none w-32 inline-block align-middle   ">
                  ごあいさつ
                </div>
                <div className="flex">
                  <div className="w-full p-2 border rounded-md ">
                    {base?.greeting}
                  </div>
                  <div
                    className="border p-3 rounded-full h-fit hover:shadow-lg"
                    onClick={() => handleEdit("editGreetingFromBase")}
                  >
                    <EditIcon />
                  </div>
                </div>
              </div>

              {/* イベント作成 */}
              <div className="flex flex-col spay1 border shadow-md hover:border-blue-500 hover:shadow-lg p-3 rounded-md cursor-pointer ">
                <div className="flex-none w-32 inline-block align-middle   ">
                  イベント
                </div>
                <div className="w-full p-2  rounded-md border  hover:border-blue-500 hover:shadow-2xl duration-150 shadow-md">
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="doMakeEventsEveryOne"
                    value={base?.id}
                    onChange={handleEventChange}
                  >
                    <FormControlLabel
                      checked={baseDoc?.doMakeEventsEveryOne === true}
                      value={base?.id}
                      control={<Radio />}
                      label="誰でも作成可"
                    />
                    <FormControlLabel
                      checked={baseDoc?.doMakeEventsEveryOne === false}
                      value={base?.id}
                      control={<Radio />}
                      label="リーダー（村長）のみ可"
                    />
                  </RadioGroup>
                </div>
              </div>

              {/* マップ公開 */}
              <div className="flex flex-col spay1 border shadow-md hover:border-blue-500 hover:shadow-lg p-3 rounded-md cursor-pointer ">
                <div className="flex-none w-32 inline-block align-middle   ">
                  マップ公開
                </div>
                <div className="w-full p-2  rounded-md border  hover:border-blue-500 hover:shadow-2xl duration-150 shadow-md">
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="releaseAddress"
                    value={base?.id}
                    onChange={handleEventChange}
                  >
                    <FormControlLabel
                      checked={baseDoc?.releaseAddress === true}
                      value={base?.id}
                      control={<Radio />}
                      label="公開"
                    />
                    <FormControlLabel
                      checked={baseDoc?.releaseAddress === false}
                      value={base?.id}
                      control={<Radio />}
                      label="非公開"
                    />
                  </RadioGroup>
                  <div className="text-xs text-red-500">
                    ※「公開」にするとセカイムラマップ上に表示されます。
                  </div>
                </div>
              </div>

              {/* 表示住所 */}
              <div className="flex flex-col spay1 border shadow-md hover:border-blue-500 hover:shadow-lg p-3 rounded-md cursor-pointer ">
                <div className="flex-none w-32 inline-block align-middle   ">
                  表示住所
                </div>
                <div className="w-full p-2 border rounded-md  hover:border-blue-500 hover:shadow-2xl duration-150 shadow-md">
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="useDummyGeoPoint"
                    value={base?.id}
                    onChange={handleEventChange}
                    // aria-disabled={baseDoc?.releaseAddress === false}
                  >
                    <FormControlLabel
                      checked={baseDoc?.useDummyGeoPoint === true}
                      value={base?.id}
                      control={<Radio />}
                      label="ダミー住所"
                      disabled={baseDoc?.releaseAddress === false}
                    />
                    <FormControlLabel
                      checked={baseDoc?.useDummyGeoPoint === false}
                      value={base?.id}
                      control={<Radio />}
                      label="本住所"
                      disabled={baseDoc?.releaseAddress === false}
                    />
                  </RadioGroup>
                  <div className="">
                    <div className="text-xs text-red-400">
                      「ダミー住所」に設定すると「セカイムラマップ」上には以下のテキストが表示されます。
                    </div>
                    <div className="text-xs text-blue-400">
                      「このポイントはダミーで近隣の場所です。」
                    </div>
                  </div>
                </div>
              </div>

              {/* ダミー住所設定 */}
              <div className="flex flex-col spay1 border shadow-md hover:border-blue-500 hover:shadow-lg p-3 rounded-md cursor-pointer ">
                <div className="flex-none w-32 inline-block align-middle   ">
                  ダミー住所設定
                </div>
                <div className="w-full p-2 border rounded-md  hover:border-blue-500 hover:shadow-2xl duration-150 shadow-md">
                  <AddAddressFromPostCode target={base} />

                  <div className="flex">
                    <div className="text-green-600 font-bold">
                      現在のダミー住所:{" "}
                    </div>
                    <div className=""> {base?.dummyAddress || "未定"}</div>
                  </div>

                  <div className="my-1 text-gray-500">
                    ※設定したダミー住所はセカイムラマップで表示されます。
                  </div>
                  <div className="my-1 text-gray-500">
                    ※マップ上に表示させるダミーポイントは事務局までお問い合わせください。
                  </div>
                </div>
              </div>

              {/* 本住所設定 */}
              <div className="flex flex-col spay1 border shadow-md hover:border-blue-500 hover:shadow-lg p-3 rounded-md cursor-pointer ">
                <div className="flex-none w-32 inline-block align-middle   ">
                  本住所設定
                </div>

                {/* 住所と経度緯度 */}
                <div className="flex items-center space-x-2">
                  <div className="flex flex-col border rounded-md hover:border-blue-500 hover:shadow-2xl duration-150 shadow-md my-2 p-5 w-full">
                    {/* 住所 */}
                    <div className="flex space-x-2 my-2 items-center">
                      <div className="w-fit">住所</div>
                      <div className="border rounded-md py-2 px-4">
                        {baseDoc?.address}
                      </div>
                      <div
                        className="rounded-full h-fit p-1 border hover:cursor-pointer hover:border-blue-500 shadow-md "
                        onClick={() => handleEdit("editAddressAndLatLng")}
                      >
                        <ModeEditIcon />
                      </div>
                    </div>
                    {/* 緯度 */}
                    <div className="flex space-x-2 my-2 items-center">
                      <div className="w-fit">緯度(lat)</div>
                      <div className="border rounded-md py-2 px-4">
                        {baseDoc?.lat}
                      </div>
                      <div
                        className="rounded-full h-fit p-1 border hover:cursor-pointer hover:border-blue-500 shadow-md "
                        onClick={() => handleEdit("editLat")}
                      >
                        <ModeEditIcon />
                      </div>
                    </div>
                    {/* 緯度 */}
                    <div className="flex space-x-2 my-2 items-center">
                      <div className="w-fit">経度(lng)</div>
                      <div className="border rounded-md py-2 px-4">
                        {baseDoc?.lng}
                      </div>
                      <div
                        className="rounded-full h-fit p-1 border hover:cursor-pointer hover:border-blue-500 shadow-md "
                        onClick={() => handleEdit("editLng")}
                      >
                        <ModeEditIcon />
                      </div>
                    </div>
                    <div
                      className="flex justify-end text-blue-500 text-xs cursor-pointer"
                      // onClick={handleMoveConfirmLatLng}
                      onClick={() => window.open("/confirmLngLat/")}
                    >
                      経度と緯度の確認方法はこちら
                    </div>
                  </div>
                  {/* 住所と経度と緯度更新 */}
                </div>
              </div>
            </div>
          </TabPanel>

          {/* 村長 */}
          <TabPanel sx={{ width: "100%" }} value="2">
            {/* 村長の一覧 */}
            <div className="text-xl font-bold mb-2">現在の村長</div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenChiefDialog(true)}
            >
              村長を追加
            </Button>
            <div className="flex flex-col space-y-3">
              {base?.chief &&
                (Array.isArray(base.chief) ? base.chief : [base.chief]) // 文字列を配列に変換
                  .filter((chiefId) => chiefId && chiefId.trim() !== "") // 空白・nullを除去
                  .map((chiefId) => (
                    <div
                      key={chiefId}
                      className="flex items-center space-x-3 border p-2 rounded-md shadow-sm bg-white"
                    >
                      {/* 村長のアバター */}
                      <div className="w-12 h-12 flex-shrink-0">
                        <GetUserInfo uid={chiefId} target="avatar" size={40} />
                      </div>
                      {/* 村長の名前 */}
                      <div className="flex-grow">
                        <GetUserInfo uid={chiefId} target="name" />
                      </div>
                      {/* 削除ボタン */}
                      <button
                        className="text-white bg-red-500 rounded-full p-2 hover:bg-red-600"
                        onClick={() => {
                          setSelectedChiefId(chiefId);
                          setOpenDeleteDialog(true);
                        }}
                      >
                        <RemoveIcon />
                      </button>
                    </div>
                  ))}
            </div>
            {/* ダイアログを呼び出す */}
            <AddBaseChiefDialog
              open={openChiefDialog}
              setOpen={setOpenChiefDialog}
              requestId={base.id}
              updateBaseChiefs={updateBaseChiefs}
            />

            {/* 削除確認ダイアログ */}
            <Dialog
              open={openDeleteDialog}
              onClose={() => setOpenDeleteDialog(false)}
            >
              <DialogTitle>村長を削除</DialogTitle>
              <DialogContent>この村長を削除しますか？</DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setOpenDeleteDialog(false)}
                  color="secondary"
                >
                  キャンセル
                </Button>
                <Button
                  onClick={handleDeleteChief}
                  color="primary"
                  variant="contained"
                >
                  削除
                </Button>
              </DialogActions>
            </Dialog>
          </TabPanel>
          {/* 作物 */}
          <TabPanel sx={{ width: "100%" }} value="3">
            <div>準備中</div>
            <div>製作中機能</div>
            <div>・取れ高月別登録</div>
            <div>・収穫量グラフ（作物別）</div>
            <div>保持している種や苗登録</div>
            <div>
              ・ドローンで上空から撮った写真をAIで解析して土地の状態などをチェック
            </div>
          </TabPanel>
          {/* 土地 */}
          <TabPanel sx={{ width: "100%" }} value="4">
            <div>準備中</div>
            <div>製作中機能</div>
            <div>・耕作可能地の登録</div>
          </TabPanel>
          {/* 設備 */}
          <TabPanel sx={{ width: "100%" }} value="5">
            <div>準備中</div>
            <div>製作中機能</div>
            <div>・発電機（水力など）の登録</div>
            <div>・トラクターなどの農工機の登録</div>
            <div>・宿泊施設・などの登録</div>
            <div>・空き部屋状況などのグラフ</div>
          </TabPanel>
        </TabContext>
      </div>
    </>
  );
};

export default BaseSetting;
