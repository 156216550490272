import React, { useState, useEffect } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  Paper,
  CircularProgress,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { collection, doc, deleteDoc, onSnapshot } from "firebase/firestore";
import { db } from "../api/firebase";
import FarmerDialog from "./FarmerDialog"; // ダイアログコンポーネントをインポート

const regions = {
  "北海道・東北": [
    "北海道",
    "青森県",
    "岩手県",
    "宮城県",
    "秋田県",
    "山形県",
    "福島県",
  ],
  関東: [
    "茨城県",
    "栃木県",
    "群馬県",
    "埼玉県",
    "千葉県",
    "東京都",
    "神奈川県",
  ],
  中部北陸: [
    "新潟県",
    "富山県",
    "石川県",
    "福井県",
    "山梨県",
    "長野県",
    "岐阜県",
    "静岡県",
    "愛知県",
  ],
  近畿: [
    "三重県",
    "滋賀県",
    "京都府",
    "大阪府",
    "兵庫県",
    "奈良県",
    "和歌山県",
  ],
  中国: ["鳥取県", "島根県", "岡山県", "広島県", "山口県"],
  四国: ["徳島県", "香川県", "愛媛県", "高知県"],
  "九州・沖縄": [
    "福岡県",
    "佐賀県",
    "長崎県",
    "熊本県",
    "大分県",
    "宮崎県",
    "鹿児島県",
    "沖縄県",
  ],
};

const extractPrefecture = (address) => {
  if (!address) return null;

  const prefectures = [
    ...regions["北海道・東北"],
    ...regions["関東"],
    ...regions["中部北陸"],
    ...regions["近畿"],
    ...regions["中国"],
    ...regions["四国"],
    ...regions["九州・沖縄"],
  ];

  return prefectures.find((prefecture) => address.includes(prefecture)) || null;
};

const FarmerListByRegion = () => {
  const [farmers, setFarmers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedFarmer, setSelectedFarmer] = useState(null); // 選択された農地
  const [dialogOpen, setDialogOpen] = useState(false); // ダイアログ表示状態

  useEffect(() => {
    const farmersRef = collection(db, "farmers");

    // FirestoreのonSnapshotを使用してリアルタイム監視
    const unsubscribe = onSnapshot(farmersRef, (snapshot) => {
      const fetchedFarmers = snapshot.docs.map((doc) => {
        const data = doc.data();
        const prefecture = extractPrefecture(data.address);
        return {
          id: doc.id,
          ...data,
          prefecture: prefecture || "その他",
        };
      });
      setFarmers(fetchedFarmers);
      setLoading(false); // データが取得できたらローディングを解除
    });

    // クリーンアップ
    return () => unsubscribe();
  }, []);

  const handleDelete = async (farmerId) => {
    if (window.confirm("本当にこの農地情報を削除しますか？")) {
      try {
        await deleteDoc(doc(db, "farmers", farmerId));
      } catch (error) {
        console.error("Error deleting farmer:", error);
      }
    }
  };

  const handleListItemClick = (farmer) => {
    setSelectedFarmer(farmer);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);

    // 最新の farmers の中から選択された farmer を取得し直す
    if (selectedFarmer) {
      const updatedFarmer = farmers.find((f) => f.id === selectedFarmer.id);
      setSelectedFarmer(updatedFarmer || null);
    }
  };

  const groupFarmersByRegionAndPrefecture = (farmers) => {
    const grouped = {};

    // 地域ごとに農家を分類
    Object.entries(regions).forEach(([region, regionPrefectures]) => {
      grouped[region] = regionPrefectures.reduce((acc, prefecture) => {
        acc[prefecture] = farmers.filter(
          (farmer) => farmer.prefecture === prefecture
        );
        return acc;
      }, {});

      // その他に分類される農家を追加
      grouped[region]["その他"] = farmers.filter(
        (farmer) =>
          farmer.prefecture === "その他" &&
          !regionPrefectures.includes(farmer.prefecture)
      );
    });

    // 地域外の「その他」
    grouped["その他"] = farmers.filter(
      (farmer) => farmer.prefecture === "その他"
    );

    return grouped;
  };

  const groupedFarmers = groupFarmersByRegionAndPrefecture(farmers);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Paper
        elevation={3}
        sx={{ paddingTop: 3, paddingX:1,  height: "80vh", overflow: "auto" }}
      >
        <Typography variant="h5" gutterBottom>
          地域別農地一覧
        </Typography>

        {Object.entries(groupedFarmers)
          .filter(([region]) => region !== "その他") // 「その他」を除外して表示
          .map(([region, prefectures]) => {
            // 地域全体の件数を計算
            const regionCount = Object.values(prefectures).reduce(
              (count, prefectureFarmers) => count + prefectureFarmers.length,
              0
            );

            return (
              <Box key={region} sx={{ marginBottom: 4 }}>
                <Typography
                  variant="h6"
                  color="primary"
                  sx={{
                    marginBottom: 2,
                    position: "sticky",
                    top: -30,
                    backgroundColor: "white",
                    zIndex: 10,
                    padding: "8px 0",
                    borderBottom: "1px solid #e0e0e0",
                  }}
                >
                  {region}（{regionCount}件）
                </Typography>
                {Object.entries(prefectures).map(
                  ([prefecture, prefectureFarmers]) => (
                    <Box key={prefecture} sx={{ marginBottom: 2 }}>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          marginBottom: 1,
                          fontWeight: "medium",
                          color: "text.primary",
                          borderBottom: "1px solid #e0e0e0",
                          paddingBottom: "2px",
                          //   position: "sticky",
                          //   top: "10px", // 地域ヘッダーの高さ分だけずらす
                          backgroundColor: "white",
                          zIndex: 9,
                        }}
                      >
                        {prefecture}（{prefectureFarmers.length}件）
                      </Typography>
                      <List>
                        {prefectureFarmers.length > 0 ? (
                          prefectureFarmers.map((farmer) => (
                            <ListItem
                              key={farmer.id}
                              sx={{
                                borderBottom: "1px solid #e0e0e0",
                                borderRadius: 2,
                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                marginBottom: 1,
                                transition: "background-color 0.3s",
                                "&:hover": {
                                  backgroundColor: "#f5f5f5",
                                },
                              }}
                              button
                              onClick={() => handleListItemClick(farmer)} // 農地をクリックでダイアログを開く
                            >
                              <ListItemText
                                primary={farmer.name}
                                secondary={
                                  <Typography
                                    sx={{
                                      display: "block",
                                      overflow: "hidden",
                                      whiteSpace: "normal", // 改行を可能にする
                                      lineHeight: "1.5em", // 行の高さを調整
                                      maxHeight: "4.5em", // 最大高さを3行分（行高1.5emで3行分に相当）
                                      textOverflow: "ellipsis", // 省略符号
                                      fontSize: "0.875rem", // 文字の大きさ（例：0.875rem）
                                      color: "text.secondary", // 薄い文字色
                                    }}
                                  >
                                    {`${farmer.address} - ${farmer.description}`}
                                  </Typography>
                                }
                              />

                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={(e) => {
                                  e.stopPropagation(); // ダイアログを開かないように
                                  handleDelete(farmer.id);
                                }}
                              >
                                <DeleteIcon color="error" />
                              </IconButton>
                            </ListItem>
                          ))
                        ) : (
                          <Typography variant="body2" color="textSecondary">
                            現在、コメ部募集中の農家さんはいません。
                          </Typography>
                        )}
                      </List>
                    </Box>
                  )
                )}
              </Box>
            );
          })}

        {/* 最後に「その他」を追加 */}
        {groupedFarmers["その他"] && groupedFarmers["その他"].length > 0 && (
          <Box sx={{ marginBottom: 4 }}>
            <Typography
              variant="h6"
              color="primary"
              sx={{
                marginBottom: 2,
                position: "sticky",
                top: 0,
                backgroundColor: "white",
                zIndex: 10,
                padding: "8px 0",
                borderBottom: "1px solid #e0e0e0",
              }}
            >
              その他（{groupedFarmers["その他"].length}件）
            </Typography>
            <List>
              {groupedFarmers["その他"].map((farmer) => (
                <ListItem
                  key={farmer.id}
                  sx={{
                    borderBottom: "1px solid #e0e0e0",
                    borderRadius: 2,
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    marginBottom: 1,
                    transition: "background-color 0.3s",
                    "&:hover": {
                      backgroundColor: "#f5f5f5",
                    },
                  }}
                  button
                  onClick={() => handleListItemClick(farmer)} // 農地をクリックでダイアログを開く
                >
                  <ListItemText
                    primary={farmer.name}
                    secondary={`${farmer.address} - ${farmer.description}`}
                  />
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={(e) => {
                      e.stopPropagation(); // ダイアログを開かないように
                      handleDelete(farmer.id);
                    }}
                  >
                    <DeleteIcon color="error" />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          </Box>
        )}
      </Paper>

      {/* ダイアログ */}
      <FarmerDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        farmer={selectedFarmer}
        onUpdate={(updatedFarmer) => {
          // farmers のリストを更新
          setFarmers((prevFarmers) =>
            prevFarmers.map((farmer) =>
              farmer.id === updatedFarmer.id ? updatedFarmer : farmer
            )
          );

          // 更新された farmer を再設定
          setSelectedFarmer(updatedFarmer);
        }}
      />
    </>
  );
};

export default FarmerListByRegion;
