import React, { useState, useEffect } from "react";
import { db, storage } from "../api/firebase"; // Firebaseの設定をインポート
import {
  collection,
  getDocs,
  addDoc,
  Timestamp,
  onSnapshot,
  query,orderBy
} from "firebase/firestore"; // FirestoreのAPI
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"; // Firebase StorageのAPI
import {
  Paper,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  ListItemIcon,
  IconButton,
  Snackbar,
  Fab,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DeleteIcon from "@mui/icons-material/Delete";
import { v4 as uuidv4 } from "uuid";
import KomebuNewsDetailDialog from "./KomebuNewsDetailDialog";
import KomebuTutorial from "./KomebuTutorial"; // チュートリアルコンポーネントを追加
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CloseIcon from "@mui/icons-material/Close";

// 画像を100KB以下にダウンサイズする関数
const resizeImage = (file) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const reader = new FileReader();

    reader.onload = (e) => {
      img.src = e.target.result;
    };

    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // 最大幅、高さを指定（例えば、幅500px, 高さ500px）
      const maxWidth = 500;
      const maxHeight = 500;

      // リサイズ比率を計算
      const ratio = Math.min(maxWidth / img.width, maxHeight / img.height);
      const width = img.width * ratio;
      const height = img.height * ratio;

      canvas.width = width;
      canvas.height = height;

      // 画像を描画してリサイズ
      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob(
        (blob) => {
          if (blob) {
            resolve(blob); // リサイズされた画像を返す
          } else {
            reject(new Error("Blob creation failed"));
          }
        },
        "image/jpeg",
        0.7
      ); // 画像形式や圧縮率を設定
    };

    img.onerror = reject;
    reader.readAsDataURL(file);
  });
};

const KomebuNewsList = ({ user }) => {
  const [newsItems, setNewsItems] = useState([]); // お知らせデータを格納する状態
  const [isDialogOpen, setDialogOpen] = useState(false); // 投稿ダイアログの開閉状態
  const [title, setTitle] = useState(""); // タイトルを格納する状態

  const [comment, setComment] = useState(""); // コメント（content）を格納する状態
  const [newImages, setNewImages] = useState([]); // 新規お知らせの画像ファイル（複数対応）
  const [loading, setLoading] = useState(false); // ローディング状態
  const [openSnackbar, setOpenSnackbar] = useState(false); // Snackbarの状態
  const [selectedNews, setSelectedNews] = useState(null); // 選択されたお知らせデータ
  const [isDetailDialogOpen, setDetailDialogOpen] = useState(false); // 詳細ダイアログの開閉状態
  const [openTutorial, setOpenTutorial] = useState(false); // チュートリアルダイアログの開閉状態

  // Firestoreからお知らせをリアルタイムで監視
  useEffect(() => {
    // Firestore からお知らせを `createdAt` の降順でリアルタイム監視
    const q = query(collection(db, "komebuNews"), orderBy("createdAt", "desc"));
  
    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const newsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(), // ドキュメントのデータを展開
        }));
        setNewsItems(newsData); // 取得したデータを状態にセット
      },
      (error) => {
        console.error("Error fetching news:", error);
      }
    );
  
    // クリーンアップ（コンポーネントのアンマウント時に監視を解除）
    return () => unsubscribe();
  }, []);
  // ダイアログの開閉ハンドラー
  const handleDialogOpen = (news) => {
    setSelectedNews(news); // 選択されたお知らせデータを保存
    setDialogOpen(true); // ダイアログを開く
  };
  const handleDialogClose = () => {
    setDialogOpen(false); // ダイアログを閉じる
    setSelectedNews(null); // 選択されたお知らせをクリア
  };

  // 詳細ダイアログの開閉ハンドラー
  const handleDetailDialogOpen = (news) => {
    setSelectedNews(news); // 選択されたお知らせデータを保存
    setDetailDialogOpen(true); // 詳細ダイアログを開く
  };
  const handleDetailDialogClose = () => {
    setDetailDialogOpen(false); // 詳細ダイアログを閉じる
    setSelectedNews(null); // 選択されたお知らせをクリア
  };

  // チュートリアルの開閉ハンドラー
  const handleOpenTutorial = () => setOpenTutorial(true);
  const handleCloseTutorial = () => setOpenTutorial(false);

  const handleImageChange = async (e) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files);
      const resizedImages = [];

      // 画像リサイズの処理
      for (let i = 0; i < filesArray.length; i++) {
        const file = filesArray[i];
        try {
          // ダウンサイズして画像を追加
          const resizedImage = await resizeImage(file);

          // リサイズされた画像を配列に追加
          resizedImages.push({
            file: resizedImage,
            preview: URL.createObjectURL(resizedImage),
            order: resizedImages.length + i, // 画像の順番を設定
          });

          // デバッグ用: リサイズされた画像を確認
          console.log("Resized Image:", resizedImage);
        } catch (error) {
          console.error("Error resizing image:", error);
          alert("画像のサイズが大きすぎます。圧縮できませんでした。");
        }
      }

      // 画像がリサイズされていれば追加
      if (resizedImages.length > 0) {
        console.log("Adding resized images to newImages:", resizedImages);
        setNewImages((prevImages) => prevImages.concat(resizedImages)); // 画像の追加
      } else {
        console.warn(
          "No images to add. Please check the resizeImage function."
        );
      }
    }
  };

  // 画像の順番変更処理（上または下に移動）
  const handleMoveImage = (index, direction) => {
    const newImagesList = [...newImages];
    const [movedImage] = newImagesList.splice(index, 1); // 移動させた画像を取り出す
    newImagesList.splice(index + direction, 0, movedImage); // 新しい位置に追加

    // 移動後の順番を更新
    newImagesList.forEach((image, idx) => {
      image.order = idx; // 順番を更新
    });

    setNewImages(newImagesList);
  };

  // 画像削除処理
  const handleImageDelete = (index) => {
    const newImagesList = [...newImages];
    newImagesList.splice(index, 1); // 指定されたインデックスの画像を削除

    // 削除後、順番を再調整
    newImagesList.forEach((image, idx) => {
      image.order = idx; // 順番を再設定
    });

    setNewImages(newImagesList);
  };

  const handlePostNews = async () => {
    if (!comment || !title) {
      alert("コメントとタイトルは必須です！");
      return;
    }

    setLoading(true);

    try {
      // 画像URLを格納する配列（orderも含める）
      let imageUrls = [];

      for (const image of newImages) {
        const storageRef = ref(
          storage,
          `komebuImage/${uuidv4()}-${image.file.name}`
        );
        const uploadTask = uploadBytesResumable(storageRef, image.file);

        await new Promise((resolve, reject) => {
          uploadTask.on(
            "state_changed",
            (snapshot) => {},
            (error) => {
              console.error("Error uploading image: ", error);
              setLoading(false);
              reject(error);
            },
            async () => {
              // アップロード完了後、画像URLを取得
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              imageUrls.push({
                url: downloadURL, // ダウンロードURL
                order: image.order, // 画像の順番
              });
              resolve();
            }
          );
        });
      }

      // 投稿用のデータ
      const newPost = {
        title: title,
        content: comment,
        images: imageUrls, // Firestoreに保存する画像URL
        createdBy: user.uid,
        createdAt: Timestamp.fromDate(new Date()),
      };

      // Firestoreに新規お知らせを追加
      await addDoc(collection(db, "komebuNews"), newPost);

      // フォームをリセット
      setTitle("");
      setComment("");
      setNewImages([]);
      handleDialogClose(); // ダイアログを閉じる
      setLoading(false); // ローディングを終了

      // Snackbarを表示
      setOpenSnackbar(true);
    } catch (error) {
      console.error("Error posting news:", error);
      setLoading(false);
    }
  };

  // Snackbarの閉じる処理
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // 更新されたnewsを保存する関数
  const handleUpdateNews = (updatedNews) => {
    setNewsItems((prevNews) =>
      prevNews.map((news) =>
        news.id === updatedNews.id ? { ...news, ...updatedNews } : news
      )
    );
  };

  return (
    <Paper sx={{ padding: 2, marginTop: 2 }}>
      <Typography variant="h5" gutterBottom>
        お知らせ
      </Typography>

      {/* 投稿ボタン（管理者のみ） */}
      {user?.isAdmin && (
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleDialogOpen}
          sx={{ marginBottom: 2 }}
        >
          お知らせ投稿
        </Button>
      )}

      <List>
        {newsItems.length === 0 ? (
          <Typography variant="body1">お知らせはありません。</Typography>
        ) : (
          newsItems.map((news) => (
            <React.Fragment key={news.id}>
              <ListItem button onClick={() => handleDetailDialogOpen(news)}>
                {/* 最初の画像を横120px、縦80pxに設定 */}
                {news.images && news.images[0] && (
                  <ListItemIcon>
                    <img
                      src={news.images[0].url}
                      alt="News"
                      style={{
                        width: "120px", // 横幅120pxに設定
                        height: "80px", // 最大縦幅80pxに設定
                        objectFit: "cover",
                        marginRight: 10,
                      }}
                    />
                  </ListItemIcon>
                )}

                {/* タイトルとコンテントと日付 */}
                <ListItemText
                  primary={<strong>{news.title}</strong>} // タイトルを強調表示
                  secondary={
                    <>
                      <div>{news.content}</div> {/* コメントを表示 */}
                      <Typography variant="body2" color="textSecondary">
                        {`投稿日: ${news.createdAt.toDate().toLocaleString()}`}{" "}
                        {/* 投稿日時 */}
                      </Typography>
                    </>
                  }
                  sx={{
                    maxWidth: "100%", // 親コンポーネントに合わせて最大幅を設定
                    overflow: "hidden", // はみ出し防止
                    wordWrap: "break-word", // 長い単語を折り返して表示
                    whiteSpace: "normal", // テキストを折り返し
                    wordBreak: "break-word", // URLも途中で折り返す
                  }}
                />
              </ListItem>
              <Divider />
            </React.Fragment>
          ))
        )}
      </List>

      {/* フローティングボタン（チュートリアル表示） */}
      <Fab
        color="primary"
        aria-label="tutorial"
        sx={{
          position: "fixed",
          top: 100,
          right: 16,
          zIndex: 2000,
          boxShadow: 3,
        }}
        onClick={handleOpenTutorial}
      >
        <HelpOutlineIcon />
      </Fab>

      {/* チュートリアルダイアログ */}
      <Dialog
        open={openTutorial}
        onClose={handleCloseTutorial}
        fullWidth
        maxWidth="sm"
        sx={{ backdropFilter: "blur(4px)",zIndex:2100}}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          チュートリアル
          <IconButton onClick={handleCloseTutorial}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <KomebuTutorial onFinish={handleCloseTutorial} />
        </DialogContent>
      </Dialog>

      {/* お知らせ投稿ダイアログ */}
      <Dialog open={isDialogOpen} onClose={handleDialogClose} fullWidth>
        <DialogTitle>お知らせ投稿</DialogTitle>
        <DialogContent>
          <TextField
            label="タイトル"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            sx={{ marginBottom: 2 }}
          />

          <TextField
            label="コメント"
            fullWidth
            multiline
            rows={4}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            sx={{ marginBottom: 2 }}
          />

          {/* 画像選択ボタン（複数選択対応） */}
          <Button
            variant="contained"
            component="label"
            startIcon={<PhotoCamera />}
            sx={{ mt: 2 }}
          >
            画像を追加
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={handleImageChange}
              multiple
            />
          </Button>

          {/* 選択された画像のプレビュー */}
          <div>
            {newImages.map((image, index) => (
              <ListItem
                key={index}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <ListItemIcon>
                  <img
                    src={image.preview}
                    alt={`preview-${index}`}
                    style={{ width: 100, height: 100, objectFit: "cover" }}
                  />
                </ListItemIcon>
                <IconButton
                  onClick={() => handleMoveImage(index, -1)}
                  disabled={index === 0}
                >
                  <ArrowUpwardIcon />
                </IconButton>
                <IconButton
                  onClick={() => handleMoveImage(index, 1)}
                  disabled={index === newImages.length - 1}
                >
                  <ArrowDownwardIcon />
                </IconButton>
                <ListItemText primary={`画像 ${index + 1}`} />
                <IconButton onClick={() => handleImageDelete(index)}>
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            閉じる
          </Button>
          <Button
            onClick={handlePostNews}
            color="primary"
            variant="contained"
            disabled={loading || !comment || !title} // タイトルかコメントが空なら投稿ボタンを無効化
          >
            {loading ? "投稿中..." : "投稿"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* 詳細ダイアログを表示 */}
      <KomebuNewsDetailDialog
        open={isDetailDialogOpen}
        onClose={handleDetailDialogClose}
        news={selectedNews}
        handleUpdateNews={handleUpdateNews} // newsを更新する関数を渡す
      />
      {/* Snackbar */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message="投稿完了しました！"
      />
    </Paper>
  );
};

export default KomebuNewsList;
