import React, { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
  MobileStepper,
} from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

import komebu1 from "../data/img/komebu1.jpg";
import komebu2 from "../data/img/komebu2.jpg";
import komebu3 from "../data/img/komebu3.jpg";

const steps = [
  {
    label: "マップを確認",
    description:
      "まずは「マップ」より、近隣のお米農家さんをご確認ください。ピンをクリックすると、農家さんの詳しい情報を見ることができます。ピンの色の違いについては、画面右上の「マーカの説明」をクリックしてください",
    // imgPath: "../data/img/komebu1.jpg",
    imgPath: komebu1,
  },
  {
    label: "グループ参加",
    description:
      "お手伝いに行きたい農家さんを決めたら、「グループ」より、その農家さんがある都道府県のグループに参加申請をしましょう。※参加をしなくても、すべてのグループのFEEDやCHATのやり取りは閲覧できますので、関心がある都道府県をそれぞれご覧になってみてください。",
    // imgPath: "/img/komebu2.jpg",
    imgPath: komebu2,
  },
  {
    label: "FEEDの利用",
    description:
      "「FEED」は文章や画像の投稿や、その記事に対しての返信、リアクションができます。これまでの話の流れが投稿されているので、一通り目を通してみましょう。新しく参加される場合は、自己紹介も投稿しましょう！",
    // imgPath: "../img/komebu3.jpg",
    imgPath: komebu3,
  },
  {
    label: "MEMBERS機能",
    description:
      "メンバーサイト以外にも、LINEなどを利用しやりとりを進めているグループもあります。こちらの一覧から確認の上、ご参加ください。https://docs.google.com/spreadsheets/d/1slkHowG5BsD5eabMO1cKasuLWSCxrxrL45CXZZB05zk/edit?usp=sharing",
    // imgPath: "/images/tutorial4.png",
  },
  {
    label: "説明会動画",
    description:
      "米部の詳細の活動については、説明会の動画でご確認ください。https://sekaimura.net/movie/I28KQrn3FmKZJEbfXs6t",
    // imgPath: "/images/tutorial5.png",
  },
  {
    label: "動画",
    description:
      "お米づくりの動画コンテンツは、アイコンの隣の「・・・」マークから「動画」をクリックし、「自分のお米を自分で作れるようになる講座」を選ぶと、動画の一覧が表示されます。\n各タイトルをクリックしてご覧ください。\n\n説明動画はこちら：\nhttps://sekaimura.net/movieChannel/Ah7YlfbBLEpDoL1BcIRn\n\n春までには目を通し、1年のお米作りの流れを頭に入れておきましょう！",
  }
  
];

const KomebuTutorial = ({ onFinish }) => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      onFinish(); // チュートリアル完了時の処理
    } else {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const linkifyText = (text) => {
    const urlRegex = /(https?:\/\/[^\s\u3000-\u303F\uFF00-\uFFEF]+)/g; // ← 全角記号までで区切る
    return text.split(urlRegex).map((part, i) =>
      urlRegex.test(part) ? (
        <React.Fragment key={i}>
          <br />
          <a
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#1976d2', textDecoration: 'underline' }}
          >
            {part}
          </a>
          <br />
        </React.Fragment>
      ) : (
        part
      )
    );
  };
  
  
  return (
    <Box sx={{ maxWidth: 600, mx: "auto", textAlign: "center", mt: 4 }}>
      <Card sx={{ p: 2, boxShadow: 3 }}>
        <SwipeableViews
          index={activeStep}
          onChangeIndex={setActiveStep}
          enableMouseEvents
          animateHeight // ← 高さアニメーションで高さを調整
        >
          {steps.map((step, index) => (
            <Box key={index} sx={{ p: 2 }}>
              {/* imgPath がある時だけ CardMedia を表示 */}
              {step.imgPath && (
                <CardMedia
                  component="img"
                  height="100"
                  image={step.imgPath}
                  alt={step.label}
                  sx={{
                    height: { xs: 100, sm: 150, md: 300 },
                    objectFit: "contain",
                    borderRadius: 2,
                  }}
                />
              )}
              <CardContent>
                <Typography variant="h6">{step.label}</Typography>
                <Typography variant="body1" sx={{ mt: 1 }}>
                  <Typography variant="body1" sx={{ mt: 1, textAlign: "left" }}>
                    {linkifyText(step.description)}
                  </Typography>
                </Typography>
              </CardContent>
            </Box>
          ))}
        </SwipeableViews>

        <MobileStepper
          variant="dots"
          steps={steps.length}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === steps.length - 1}
            >
              {activeStep === steps.length - 1 ? "完了" : "次へ"}
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              <KeyboardArrowLeft />
              戻る
            </Button>
          }
          sx={{ mt: 2 }}
        />
        <Button sx={{ mt: 1 }} onClick={onFinish} color="secondary">
          スキップ
        </Button>
      </Card>
    </Box>
  );
};

export default KomebuTutorial;
