// src/components/HeaderRightMenus.js

import React, { useState, useEffect, memo } from "react";
import { useNavigate } from "react-router-dom";
import { doc, updateDoc, onSnapshot } from "firebase/firestore";
import { db, auth } from "../api/firebase";
import { signOut } from "firebase/auth";
// Icons
import LocationOnIcon from "@mui/icons-material/LocationOn";
import HomeIcon from "@mui/icons-material/Home";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import BuildIcon from "@mui/icons-material/Build";
import LogoutIcon from "@mui/icons-material/Logout";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import EventIcon from "@mui/icons-material/Event";
import GroupsIcon from "@mui/icons-material/Groups";
import ForestIcon from "@mui/icons-material/Forest";
import ViewTimelineIcon from "@mui/icons-material/ViewTimeline";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import RiceBowlIcon from "@mui/icons-material/RiceBowl";
import EmojiNatureIcon from "@mui/icons-material/EmojiNature"; // 「コメ部」のアイコンとして使用
// MaterialUI Components
import {
  Avatar,
  Button,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
// Custom Components
import DropdownItem from "./DropdownItem";
import LoaderHeaderPic from "./LoaderHeaderPic";
import ApprovalPendingIcon from "./ApprovalPendingIcon";
import CreateHeaderMenu from "./CreateHeaderMenu";
import { useNotification } from "../redux/useNotification";
import { useSnackbar } from "../redux/useSnackBar";
import RegisterVillager from "./RegisterVillager";
import DisplaySignUp from "./DisplaySignUp";
import { useAuth } from "../context/auth";
import Loader1Row from "./Loader1Row";
import Notifications from "./Notifications";
import NotificationsInMessage from "./NotificationsInMessage";
import QuestionForAdminIcon from "./QuestionForAdminIcon";
import ModalForm from "./ModalForm";
import AvailableRunru from "./AvailableRunru";
import HeaderMenus from "./HeaderMenus";
import VerifiedIcon from "@mui/icons-material/Verified"; // 追加


const HeaderRightMenus = memo(() => {
  const { displayNotification } = useNotification();
  const { displaySnackbar } = useSnackbar();
  const { user } = useAuth();
  const [userInfo, setUserInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openRunruDialog, setOpenRunruDialog] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [form, setForm] = useState("");
  const [target, setTarget] = useState("");

  const navigate = useNavigate();

  // 共通のナビゲーション関数
  const handleNavigate = (path, checkCurrentPath = false) => {
    if (!checkCurrentPath || window.location.pathname !== path) {
      navigate(path);
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
    }
  };

  // 各ページへのハンドラー
  const handleToHome = () => handleNavigate("/home", true);
  const handleToProfile = () => handleNavigate("/profile");
  const handleToAdminSetting = () => handleNavigate("/adminsetting");
  const handleToManuals = () => handleNavigate("/manuals");
  const handleToMap = () => handleNavigate("/map");
  const handleToChat = () => handleNavigate("/chat");
  const handleToMovie = () => handleNavigate("/movies");
  const handleToDdp = () => handleNavigate("/ddps");
  const handleToMyPage = () => handleNavigate(`/user/${auth.currentUser.uid}`);
  const handleToEvent = () => handleNavigate("/events");
  const handleToGroup = () => handleNavigate("/groups");
  const handleToBases = () => handleNavigate("/bases");
  const handleToRelease = () => handleNavigate("/release");
  const handleToKomebu = () => handleNavigate("/komebu"); // 実際のルートに置き換えてください

  const handleEdit = (e, url) => {
    setIsOpen(true);
    setTarget(url);
    setForm(e);
  };

  const displayConstruction = () => {
    displayNotification({
      message: "只今、制作中です。",
    });
  };

  const handleSignout = async () => {
    await updateDoc(doc(db, "users", auth.currentUser.uid), {
      isOnline: false,
      isLogin: false,
    });
    await signOut(auth);
    navigate("/", { replace: true });
  };

  useEffect(() => {
    if (user) {
      const unsubscribe = onSnapshot(doc(db, "users", user.uid), (doc) => {
        setUserInfo(doc.data());
      });
      return () => unsubscribe();
    }
  }, [user]);

  useEffect(() => {
    setLoading(false);
  }, [userInfo?.isVillager]);

  // ステータスマークコンポーネント
  const UserStatusMark = ({ status }) => {
    if (status === null) {
      return (
        <div className="border border-red-500 bg-red-200 bg-opacity-75 rounded-xl px-2 py-1 w-fit">
          <div className="text-xs text-red-700">Free</div>
        </div>
      );
    } else if (status === false) {
      return (
        <div className="border border-blue-500 bg-blue-200 bg-opacity-75 rounded-xl px-2 py-1 w-fit">
          <div className="text-xs text-blue-700">user</div>
        </div>
      );
    } else if (status === true) {
      return (
        <div className="border border-green-500 bg-green-200 bg-opacity-75 rounded-xl px-2 py-1 w-fit">
          <div className="text-xs text-green-700">Members</div>
        </div>
      );
    } else {
      return null; // 未知のステータスの場合は何も表示しない
    }
  };

  // メニューコンポーネント
  // const Menus = () => {
  //   return (
  //     <>
  //       <CreateHeaderMenu
  //         text="動画"
  //         icon={LiveTvIcon}
  //         onclick={handleToMovie}
  //         complete
  //       />
  //       <CreateHeaderMenu
  //         text="DDP"
  //         icon={VolunteerActivismIcon}
  //         onclick={handleToDdp}
  //         complete
  //       />
  //       <CreateHeaderMenu
  //         text="マップ"
  //         icon={LocationOnIcon}
  //         onclick={handleToMap}
  //         complete
  //       />
  //       {userInfo?.isVillager && (
  //         <CreateHeaderMenu
  //           text="拠点"
  //           icon={ForestIcon}
  //           onclick={handleToBases}
  //           complete
  //         />
  //       )}
  //       {userInfo?.isVillager && (
  //         <CreateHeaderMenu
  //           text="グループ"
  //           icon={GroupsIcon}
  //           onclick={handleToGroup}
  //           complete
  //         />
  //       )}
  //       <CreateHeaderMenu
  //         text="イベント"
  //         icon={EventIcon}
  //         onclick={handleToEvent}
  //         complete
  //       />
  //       <CreateHeaderMenu
  //         text="リリース"
  //         icon={ViewTimelineIcon}
  //         onclick={handleToRelease}
  //         complete
  //       />
  //       {/* 管理者のみ表示される「コメ部」メニュー */}
  //       {userInfo?.isAdmin && (
  //         <CreateHeaderMenu
  //           text={
  //             <span style={{ display: 'flex', alignItems: 'center' }}>
  //               コメ部
  //               <span style={{ color: 'red', marginLeft: '4px', fontSize: '0.75rem' }}>New!!!</span>
  //             </span>
  //           }
  //           icon={RiceBowlIcon} // 必要に応じて適切なアイコンに変更してください
  //           onclick={handleToKomebu}
  //           complete
  //         />
  //       )}
  //       {userInfo?.isVillager && window.location.href.indexOf("chat") === -1 && (
  //         <NotificationsInMessage />
  //       )}
  //       <Notifications />
  //       {/* 管理者向けの追加アイコン */}
  //       {userInfo?.isAdmin && <ApprovalPendingIcon />}
  //       {userInfo?.isAdmin && <QuestionForAdminIcon />}
  //     </>
  //   );
  // };

  // スマホ用メニューコンポーネント
  const MenusOfSmartPhone = () => {
    return (
      <div className="flex flex-col z-40 bg-white">
        <DropdownItem
          title="動画"
          Icon={LiveTvIcon}
          onClick={handleToMovie}
          complete
        />
        <DropdownItem
          title="DDP"
          Icon={VolunteerActivismIcon}
          onClick={handleToDdp}
          complete
        />
        <DropdownItem
          title="マップ"
          Icon={LocationOnIcon}
          onClick={handleToMap}
          complete
        />
        {userInfo?.isVillager && (
          <DropdownItem
            title="拠点"
            Icon={ForestIcon}
            onClick={handleToBases}
            complete
          />
        )}
        {userInfo?.isVillager && (
          <DropdownItem
            title="グループ"
            Icon={GroupsIcon}
            onClick={handleToGroup}
            complete
          />
        )}
        <DropdownItem
          title="マイページ"
          Icon={HomeIcon}
          onClick={handleToMyPage}
          complete
        />
        <DropdownItem
          title="イベント"
          Icon={EventIcon}
          onClick={handleToEvent}
          complete
        />
        {/* <DropdownItem title="リリース" Icon={ViewTimelineIcon} onClick={handleToRelease} complete /> */}
        {/* 管理者のみ表示される「コメ部」メニュー */}
        <DropdownItem
          title={
            <span style={{ display: "flex", alignItems: "center" }}>
              コメ部
              <span
                style={{ color: "red", marginLeft: "4px", fontSize: "0.75rem" }}
              >
                New!!!
              </span>
            </span>
          }
          Icon={RiceBowlIcon} // 必要に応じて適切なアイコンに変更してください
          onClick={handleToKomebu}
          complete
        />
      </div>
    );
  };

  // アバタークリック時のドロップダウンメニューコンポーネント
  const AvatarClickDropMenu = () => {
    return (
      <>
        {!userInfo?.isVillager && (
          <CreateHeaderMenu
            text="動画"
            icon={LiveTvIcon}
            onclick={handleToMovie}
            complete
          />
        )}
        {!userInfo?.isVillager && (
          <CreateHeaderMenu
            text="DDP"
            icon={VolunteerActivismIcon}
            onclick={handleToDdp}
            complete
          />
        )}
        {!userInfo?.isVillager && (
          <CreateHeaderMenu
            text="マップ"
            icon={LocationOnIcon}
            onclick={handleToMap}
            complete
          />
        )}
        {!userInfo?.isVillager && userInfo?.isMasterMind && (
          <CreateHeaderMenu
            text="コメ部"
            icon={RiceBowlIcon}
            onclick={handleToKomebu}
            complete
          />
        )}

        {!userInfo?.isVillager && !userInfo?.isMasterMind && (
          <CreateHeaderMenu
            text="MM認証"
            icon={VerifiedIcon}
            onclick={() => (window.location.href = "/verifing")} // 認証画面への遷移
            complete
          />
        )}


        <div className="dropdown dropdown-end bg-white rounded-full">
          <div
            tabIndex="0"
            className="flex h-full w-12 rounded-full sm:w-10 justify-center items-center"
          >
            {userInfo?.avatar && (
              <Avatar src={userInfo?.avatar || <LoaderHeaderPic />} />
            )}
          </div>

          <div
            tabIndex="0"
            className="dropdown-content menu p-2 border shadow-xl rounded-box w-52 bg-white"
            onClick={(e) => e.stopPropagation()} // ドロップダウンを閉じないようにする
            onMouseDown={(e) => e.stopPropagation()} // ドロップダウンを閉じないようにする
          >
            <div className="flex flex-col">
              <DropdownItem
                title="HOME"
                Icon={HomeIcon}
                onClick={handleToHome}
                complete
              />
              <DropdownItem
                title="プロフィール設定"
                Icon={PermContactCalendarIcon}
                onClick={handleToProfile}
                complete
              />
              <DropdownItem
                title="使い方マニュアル"
                Icon={MenuBookIcon}
                onClick={handleToManuals}
                complete
              />
              {userInfo?.isAdmin && (
                <DropdownItem
                  title="管理者設定"
                  Icon={BuildIcon}
                  onClick={handleToAdminSetting}
                  complete
                />
              )}
              {userInfo?.isAdmin && <ApprovalPendingIcon />}
              {userInfo?.isAdmin && <QuestionForAdminIcon />}
              <DropdownItem
                title="お問い合わせ"
                Icon={ContactMailIcon}
                onClick={() => handleEdit("inquiry", "/inquiry")}
                complete
              />
              <DropdownItem
                title="ログアウト"
                Icon={LogoutIcon}
                onClick={handleSignout}
                complete
              />
              <Divider />
              <div className="mt-2">
                <div className="my-2 flex space-x-2 items-center">
                  {userInfo?.isVillager ? (
                    <UserStatusMark status={userInfo?.isVillager} />
                  ) : (
                    <UserStatusMark status={userInfo?.isVillager} />
                  )}
                  {!userInfo?.isVillager && <RegisterVillager />}
                </div>
                <div className="flex space-x-2 items-center border p-1 rounded-md mb-2">
                  <Avatar src={userInfo?.avatar} sizes="small" />
                  <div className="flex space-x-2 line-clamp-1">
                    <div>
                      {userInfo?.lastName}
                      {userInfo?.firstName}
                    </div>
                  </div>
                </div>
                <div
                  className="p-2 border rounded-md mt-2 cursor-pointer"
                  onClick={() => setOpenRunruDialog(true)}
                >
                  <div className=" text-xs text-gray-700">現在ルンル</div>
                  <div className="text-xs text-gray-500">クリックで表示</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Dialog
          open={openRunruDialog}
          onClose={() => setOpenRunruDialog(false)}
        >
          <DialogTitle>現在ルンル</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <AvailableRunru uid={auth.currentUser.uid} />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenRunruDialog(false)} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  if (!user) return <DisplaySignUp />;
  if (loading) return <Loader1Row />;
  if (!userInfo) return <Loader1Row />;
  if (!userInfo?.isVillager) return <AvatarClickDropMenu />;

  return (
    <>
      <ModalForm
        form={form}
        setOpen={setIsOpen}
        open={isOpen}
        target={target}
      />

      <div className="flex items-center h-full ">
        <div className="flex justify-center items-center "
                // style={{
                //   minWidth: "fit-content", // テキストの幅に合わせる
                //   maxWidth: "80px", // 極端に広がらないよう制限
                //   wordBreak: "break-word", // 長いテキストを折り返す
                //   textAlign: "center", // テキストを中央揃え
                // }}
        >
          <div className="hidden md:flex space-x-2 mx-3 ">
            <HeaderMenus />
          </div>
          {window.location.href.indexOf("chat") === -1 && (
            <div className="md:hidden  mx-2">
              <NotificationsInMessage />
            </div>
          )}
          <div className="md:hidden">
            <Notifications />
          </div>

          <div className="dropdown dropdown-end md:hidden mr-2 ">
            <div
              tabIndex="1"
              className="flex w-12 sm:w-10 justify-center items-center"
            >
              <div className=" border rounded-full p-1 ">
                <MoreVertRoundedIcon />
              </div>
            </div>
            <div
              tabIndex="1"
              className="dropdown-content bg-white menu p-2 border shadow-xl rounded-box w-52"
            >
              <MenusOfSmartPhone />
            </div>
          </div>
          <AvatarClickDropMenu />
        </div>
      </div>
    </>
  );
});

export default HeaderRightMenus;
