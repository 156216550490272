import React, { useState, useEffect } from "react";
import "firebase/database";
import { auth, db } from "../api/firebase";
import { doc, updateDoc, onSnapshot } from "firebase/firestore";
import { useDocument } from "react-firebase-hooks/firestore";
import Loader1Row from "./Loader1Row";
import { Button, TextareaAutosize } from "@mui/material";

const EditableContent = ({
  collection,
  documentId,
  target,
  moreCollection,
  contentId,
  fontSize,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [editedData, setEditedData] = useState(null);

  const [user, setUser] = useState("");
  useEffect(() => {
    const getUser = async () => {
      onSnapshot(doc(db, "users", auth.currentUser.uid), (doc) => {
        setUser({ id: doc.id, ...doc.data() });
      });
    };
    getUser();
  }, []);

  const docRef = doc(db, collection, documentId, moreCollection, contentId);
  const [dataDoc] = useDocument(docRef);
  const data = dataDoc?.data()?.[target];

  const saveData = async () => {
    if (!editedData) {
      setEditMode(false);
      return;
    }

    if (editedData[target].length === 0) {
      setEditMode(false);
      return;
    }

    await updateDoc(docRef, {
      [target]: editedData[target],
    });
    setEditMode(false);
  };

  const handleEdit = (e) => {
    setEditedData({
      ...editedData,
      [e.target.name]: e.target.value,
    });
  };

  const enterEditMode = () => {
    setEditMode(true);
  };

  const cancelEdit = () => {
    setEditMode(false);
    setEditedData(null);
  };

  // リンクを手動で処理する関数
  const linkifyText = (text) => {
    if (!text) return null; // textがundefinedまたはnullなら何も表示しない

    const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
    return text.split(urlRegex).map((part, index) => {
      if (part.match(urlRegex)) {
        return (
          <a key={index} href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      }
      return part; // リンク以外の部分はそのまま表示
    });
  };

  return (
    <div>
      {!data && <Loader1Row />}
      {editMode && user?.isAdmin ? (
        <>
          <div className="grid grid-cols-1 w-full space-x-2 items-center">
            <TextareaAutosize
              name={target}
              style={{ resize: "none", fontSize: "16px" }}
              onChange={handleEdit}
              placeholder={data}
              defaultValue={data}
              className="p-1 w-full scrollbar-hide"
              minRows={3}
              maxRows={5}
            />
            <Button onClick={saveData}>✓</Button>
          </div>
        </>
      ) : (
        <div
          className={` 
            ${fontSize === "小" && "text-xl"}
            ${fontSize === "中" && "text-2xl"}
            ${fontSize === "大" && "text-4xl"}
            ${fontSize === "特大" && "text-5xl"}
            whitespace-pre-wrap break-words`}
          onClick={enterEditMode}
        >
          {/* リンクを手動で認識して表示 */}
          {linkifyText(data)} {/* ここでtextがundefinedならnullを返します */}
        </div>
      )}
    </div>
  );
};

export default EditableContent;
