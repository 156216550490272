import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Snackbar,
  Alert,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  LinearProgress,
} from "@mui/material";
import { db, auth } from "../api/firebase";
import {
  collection,
  getDocs,
  doc,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";

const TokenRegistration = () => {
  const [tokenList, setTokenList] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [progress, setProgress] = useState(0);
  const [estimatedTime, setEstimatedTime] = useState(0);
  const [processedCount, setProcessedCount] = useState(0);
  const [totalTokens, setTotalTokens] = useState(0);
  const [showProgressDialog, setShowProgressDialog] = useState(false);

  const handleRegisterTokens = async () => {
    setLoading(true);
    setMessage(null);
    setProgress(0);
    setEstimatedTime(0);
    setProcessedCount(0);
    setShowProgressDialog(true);

    const tokens = tokenList
      .split("\n")
      .map((token) => token.trim())
      .filter(Boolean);

    const total = tokens.length;
    setTotalTokens(total);

    if (total === 0) {
      setMessage({ error: "トークンが入力されていません。" });
      setShowProgressDialog(false);
      setLoading(false);
      return;
    }

    try {
      const existingTokens = new Map();

      // 既存のトークンを取得
      const querySnapshot = await getDocs(collection(db, "masterMindTokens"));
      querySnapshot.forEach((docSnap) => {
        const data = docSnap.data();
        if (data.token) {
          existingTokens.set(data.token, docSnap.id);
        }
      });

      let successCount = 0;
      let updatedCount = 0;
      const startTime = Date.now();

      // トークンの登録または更新
      for (let i = 0; i < total; i++) {
        const token = tokens[i];
        const docId = existingTokens.get(token);

        if (docId) {
          // トークンが存在する場合は更新
          await setDoc(
            doc(db, "masterMindTokens", docId),
            {
              token,
              updatedAt: serverTimestamp(),
              updatedBy: auth.currentUser?.uid || "anonymous",
            },
            { merge: true }
          );
          updatedCount++;
        } else {
          // トークンが存在しない場合は新規登録
          await setDoc(doc(collection(db, "masterMindTokens")), {
            token,
            registeredAt: serverTimestamp(),
            registeredBy: auth.currentUser?.uid || "anonymous",
          });
          successCount++;
        }

        // 進捗更新
        const currentTime = Date.now();
        const elapsedTime = (currentTime - startTime) / 1000; // 経過時間 (秒)
        const estimatedTotalTime = (elapsedTime / (i + 1)) * total; // 総予測時間
        const remainingTime = Math.max(0, estimatedTotalTime - elapsedTime); // 残り時間予測

        setProcessedCount(i + 1); // 現在の処理件数を更新
        setProgress(((i + 1) / total) * 100);
        setEstimatedTime(remainingTime.toFixed(1)); // 小数点1桁で表示
      }

      setMessage({ successCount, updatedCount });
    } catch (err) {
      console.error("Error registering tokens:", err);
      setMessage({ error: "トークンの登録中にエラーが発生しました。" });
    } finally {
      setLoading(false);
      setShowProgressDialog(false);
      setTokenList(""); // 入力フィールドをクリア
    }
  };

  return (
    <Box
      sx={{
        maxWidth: 600,
        margin: "auto",
        padding: 3,
        boxShadow: 3,
        borderRadius: 2,
      }}
    >
      <Typography variant="h4" gutterBottom>
        マスターマインドトークン登録
      </Typography>

      <TextField
        label="トークン一覧"
        multiline
        rows={10}
        fullWidth
        value={tokenList}
        onChange={(e) => setTokenList(e.target.value)}
        placeholder="トークンを改行区切りで貼り付けてください"
        variant="outlined"
        sx={{ marginBottom: 3 }}
      />

      <Button
        onClick={handleRegisterTokens}
        variant="contained"
        color="primary"
        fullWidth
        disabled={loading}
      >
        {loading ? "登録中..." : "登録"}
      </Button>

      {message && message.successCount !== undefined && (
        <Snackbar
          open={!!message}
          autoHideDuration={6000}
          onClose={() => setMessage(null)}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert severity="info" sx={{ width: "100%" }}>
            {message.successCount}件、新規登録されました。
            <br />
            {message.updatedCount}件、更新されました。
          </Alert>
        </Snackbar>
      )}
      {message && message.error && (
        <Snackbar
          open={!!message.error}
          autoHideDuration={6000}
          onClose={() => setMessage(null)}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            {message.error}
          </Alert>
        </Snackbar>
      )}

      {/* 進捗ダイアログ */}
      <Dialog open={showProgressDialog}>
        <DialogTitle>トークン登録中...</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            進捗: {progress.toFixed(1)}%
          </Typography>
          <LinearProgress variant="determinate" value={progress} sx={{ my: 2 }} />
          <Typography variant="body2">
            処理中: {processedCount} / {totalTokens} 件
          </Typography>
          <Typography variant="body2">
            残り時間の目安: {estimatedTime} 秒
          </Typography>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default TokenRegistration;
