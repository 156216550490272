import {
  collection,
  doc,
  getDoc,
  limit,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { auth, db } from "./api/firebase";
import BackImage from "./components/BackImage";
import ProfilePicture from "./components/ProfilePicture";
import TopPosts from "./components/TopPosts";

import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import CakeIcon from "@mui/icons-material/Cake";
import Box from "@mui/material/Box";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WcIcon from "@mui/icons-material/Wc";
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";
import BloodtypeIcon from "@mui/icons-material/Bloodtype";
import ThreePIcon from "@mui/icons-material/ThreeP";
import WorkIcon from "@mui/icons-material/Work";
import UserPictures from "./components/UserPictures";
import UserDDPs from "./components/UserDDPs";
import Myshops from "./components/Myshops";
import UserShops from "./components/UserShops";
import MessageIcon from "@mui/icons-material/Message";
import DirectMessage from "./components/DirectMessage";
import { Paper, Typography } from "@mui/material";

const User = () => {
  const { id } = useParams();
  const [user, setUser] = useState("");
  const [stickyClass, setStickyClass] = useState("top-16");

  useEffect(() => {
    const init = () => {
      getDoc(doc(db, "users", id)).then((elm) =>
        setUser({ id: elm.id, ...elm.data() })
      );
    };
    id && init();

    // ウィンドウのリサイズイベントを監視
    window.addEventListener("resize", handleWindowResize);
    return () => {
      // コンポーネントがアンマウントされるときにイベントリスナーをクリーンアップ
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [id]);

  const [userInfo, setUserInfo] = useState("");
  useEffect(() => {
    const init = async () => {
      const userRef = await getDoc(doc(db, "users", auth.currentUser.uid));
      if (userRef.exists()) {
        setUserInfo({ id: userRef.id, ...userRef.data() });
      }
    };
    init();
  }, []);

  // ウィンドウのリサイズ時にクラスを更新
  const handleWindowResize = () => {
    if (window.innerWidth >= 600) {
      setStickyClass("top-[64px] ");
    } else {
      setStickyClass("top-[55px] ");
    }
  };

  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [ddp, setDDP] = useState(null);

  useEffect(() => {
    if (!id) return;

    // ユーザのDDPsコレクションへの参照を作成
    const ddpsRef = collection(db, "users", id, "ddps");
    // ddpsコレクション内のドキュメントをupdatedAtの降順で並び替え、最初の1件のみを取得するクエリを作成
    const ddpsQuery = query(ddpsRef, orderBy("updatedAt", "desc"), limit(1));

    const unsubscribe = onSnapshot(ddpsQuery, (snapshot) => {
      if (!snapshot.empty) {
        // 最新のDDPが見つかった場合は、そのドキュメントを取得してセットする
        snapshot.forEach((doc) => {
          const ddpData = doc.data();
          if (ddpData.display) {
            setDDP(ddpData);
          } else {
            setDDP(null); // displayがfalseの場合はDDPを表示しない
          }
        });
      } else {
        setDDP(null); // DDPが見つからない場合はnullをセット
      }
    });

    return () => unsubscribe()  ;
  }, [id]);

  return (
    <>
      <div className="w-full flex flex-col items-center justify-center min-h-screen  ">
        {/* 上部　イメージと名前 */}
        <div className="flex flex-col max-w-5xl w-full justify-center border-b shadow-sm">
          <div className="relative max-w-5xl mx-auto w-full ">
            <div className="mx-auto max-w-5xl">
              <BackImage noEdit id={id} />
              <ProfilePicture noEdit id={id} />
            </div>
          </div>

          <div className="flex mt-2 sm:mt-4 justify-center font-bold text-4xl">
            {user?.lastName + user?.firstName}
          </div>

          {userInfo?.isVillager && (
            <div className="flex mt-2 sm:mt-4 justify-center font-bold text-4xl">
              {id !== auth.currentUser.uid && <DirectMessage memberId={id} />}
              {/* <MessageIcon /> */}
            </div>
          )}

          <div className=" p-4 flex mt-2 sm:mt-4 justify-center  whitespace-pre-wrap break-words">
            {user?.selfIntroduction}
          </div>

          {ddp && (
            <div className="flex flex-col p-4 rounded-lg shadow-md mb-4 text-sm font-medium text-gray-600 bg-white hover:shadow-lg transition-shadow duration-300">
              <div className="flex items-center justify-between mb-2">
                <span className="text-lg text-blue-500 font-semibold">DDP</span>
              </div>
              <div className="text-base text-gray-700 break-words whitespace-normal">
                <p>{ddp.ddp}</p>
              </div>
            </div>
          )}
        </div>

        {/* 下部 */}
        <div className="grid grid-cols-1  max-w-5xl mx-auto w-full">
          <TabContext value={value}>
            {/* リスト */}
            <div
              className={`sticky ${stickyClass} font-bold bg-back z-10 text-2xl py-5 mt-5`}
            >
              <TabList
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                onChange={handleChange}
                sx={{ width: "100%" }} // 幅を100%に設定
                //   orientation="vertical"
              >
                <Tab label="基本情報" value="1" />
                <Tab label="FEED" value="2" />
                <Tab label="写真" value="3" />
                <Tab label="DDP" value="4" />
                <Tab label="お店" value="5" />
              </TabList>
            </div>

            {/* 基本情報 */}
            <TabPanel sx={{ width: "100%", padding: "0" }} value="1">
              <Box sx={{ width: "100%", bgcolor: "#f9f9f9", p: 2 }}>
                <Typography variant="h6" color="text.secondary" gutterBottom>
                  基本情報
                </Typography>
                <Paper elevation={2} sx={{ p: 2, mb: 2 }}>
                  <div className="flex items-center space-x-4">
                    <CakeIcon color="secondary" />
                    <Typography variant="body1">
                      誕生日:{" "}
                      {user?.displayBirthDay
                        ? `${user?.birthyear}/${user?.birthmonth}/${user?.birthday}`
                        : "非公開"}
                    </Typography>
                  </div>
                </Paper>

                <Paper elevation={2} sx={{ p: 2, mb: 2 }}>
                  <div className="flex items-center space-x-4">
                    <WcIcon color="secondary" />
                    <Typography variant="body1">
                      性別: {user?.displaySexType ? user?.sextype : "非公開"}
                    </Typography>
                  </div>
                </Paper>

                <Paper elevation={2} sx={{ p: 2, mb: 2 }}>
                  <div className="flex items-center space-x-4">
                    <PersonPinCircleIcon color="secondary" />
                    <Typography variant="body1">
                      居住地:{" "}
                      {user?.displayPrefecture ? user?.prefecture : "非公開"}
                    </Typography>
                  </div>
                </Paper>

                <Paper elevation={2} sx={{ p: 2, mb: 2 }}>
                  <div className="flex items-center space-x-4">
                    <BloodtypeIcon color="secondary" />
                    <Typography variant="body1">
                      血液型:{" "}
                      {user?.displayBloodType ? user?.bloodtype : "非公開"}
                    </Typography>
                  </div>
                </Paper>

                <Paper elevation={2} sx={{ p: 2, mb: 2 }}>
                  <div className="flex items-center space-x-4">
                    <WorkIcon color="secondary" />
                    <Typography variant="body1">
                      職業: {user?.job || "非公開"}
                    </Typography>
                  </div>
                </Paper>
              </Box>
            </TabPanel>

            {/* FEED */}
            <TabPanel
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                padding: "0",
              }}
              value="2"
            >
              <div className="max-w-full">
                <TopPosts id={id} />
              </div>
            </TabPanel>
            {/* 写真 */}
            <TabPanel sx={{ width: "100%" }} value="3">
              <UserPictures id={id} />
            </TabPanel>
            {/* DDP */}
            <TabPanel sx={{ width: "100%" }} value="4">
              <UserDDPs id={id} />
            </TabPanel>
            {/* お店 */}
            <TabPanel sx={{ width: "100%" }} value="5">
              <UserShops id={id} />
              {/* <Myshops id={id} /> */}
            </TabPanel>
          </TabContext>
        </div>

        {/* <div className="flex flex-col w-full justify-center border-b shadow-sm">
          <div className="relative max-w-3xl mx-auto">
            <BackImage noEdit id={id} />
            <ProfilePicture noEdit id={id} />
          </div>
          <div className="flex mt-24 sm:mt-32  justify-center font-bold text-4xl">
            {user?.lastName + user?.firstName}
          </div>
        </div> */}

        {/* <div className="flex justify-center max-w-4xl  rounded-md shadow-md mt-5">
          <div className="grid grid-cols-1  w-full">
            <div
              className={`sticky ${stickyClass} font-bold bg-back z-10 text-2xl py-5 mt-5`}
            >
              自己紹介
            </div>
            <div className=" whitespace-pre-wrap  break-words p-2  rounded-md text-gray-700 w-fit">
              {user?.selfIntroduction}
            </div>

            <div
              className={`sticky ${stickyClass} font-bold bg-back z-10 text-2xl py-5 mt-5`}
            >
              Feed
            </div>
            <div>
              <TopPosts id={id} />
            </div>

            <div className="sticky top-0">
              <div>Feed</div>
              <div>写真</div>
              <div>Like数</div>

              <div>DDP</div>
              <div>気になる人に追加ボタン</div>
              <div>メッセンジャーを開始するボタン</div>
              <div>アニマルロック</div>
              <div>所属拠点</div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default User;
