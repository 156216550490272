import React, { useEffect, useState } from "react";
import {
  getDoc,
  doc,
  setDoc,
  addDoc,
  collection,
  serverTimestamp,
} from "firebase/firestore";
import { auth, db } from "../api/firebase";

// redux - use
import { useNotification } from "../redux/useNotification";

const AddBase = ({ setOpen, requestId }) => {
  // redux - Notification
  const { displayNotification } = useNotification();

  // ユーザ取得関連
  const [base, setbase] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // リクエスト取得
  useEffect(() => {
    if (requestId) {
      getDoc(doc(db, "base", requestId)).then((doSnap) => {
        if (doSnap.exists) {
          setbase(doSnap.data());
        }
      });
    }
  }, [requestId]);

  // オーナーがいる場合は、オーナーに、それ以外はセカイムラ事務局に 2023.05.18
  // const [owner, setOwner] = useState("");
  // useEffect(() => {
  //   const initSetOwner = () => {
  //     if (base?.chief) {
  //       getDoc(doc(db, "users", base?.chief)).then((doc) => {
  //         setOwner({ id: doc.id, ...doc.data() });
  //       });
  //     } else {
  //       getDoc(doc(db, "users", "oRSoeN1rlVSiZmt6px68GyhgP6j1")).then((doc) => {
  //         setOwner({ id: doc.id, ...doc.data() });
  //       });
  //     }
  //   };

  //   base && initSetOwner();
  // }, [base]);

  // 複数村長対応 20250306
  const [owners, setOwners] = useState([]);

  useEffect(() => {
    const initSetOwners = async () => {
      if (base?.chief) {
        let chiefArray = Array.isArray(base.chief) ? base.chief : [base.chief];
  
        // 空白や null の除去
        chiefArray = chiefArray.filter((id) => id && id.trim() !== "");
  
        // `owners` に村長たちの情報をセット
        const ownerDocs = await Promise.all(
          chiefArray.map(async (chiefId) => {
            const docSnap = await getDoc(doc(db, "users", chiefId));
            return docSnap.exists() ? { id: docSnap.id, ...docSnap.data() } : null;
          })
        );
  
        // 有効なデータのみセット
        setOwners(ownerDocs.filter(Boolean));
      } else {
        // 村長がいない場合は、事務局に通知
        const docSnap = await getDoc(doc(db, "users", "oRSoeN1rlVSiZmt6px68GyhgP6j1"));
        if (docSnap.exists()) {
          setOwners([{ id: docSnap.id, ...docSnap.data() }]);
        }
      }
    };
  
    base && initSetOwners();
  }, [base]);


  const [user, setUser] = useState("");
  useEffect(() => {
    const initSetUser = () => {
      getDoc(doc(db, "users", auth.currentUser.uid)).then((doc) => {
        setUser({ id: doc.id, ...doc.data() });
      });
    };
    initSetUser();
  }, []);

  // console.log("group =>", group);
  // console.log("owner =>", owner);
  // console.log("user =>", user);

  //   更新処理
  // const handleDelete = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);
  //   try {
  //     await setDoc(
  //       doc(db, "base", requestId, "members", auth.currentUser.uid),
  //       {
  //         isMember: false,
  //         isOwner: false,
  //         createdAt: serverTimestamp(),
  //         uid: auth.currentUser.uid,
  //         isApplication: true,
  //       }
  //     );
  //     // 通知
  //     await addDoc(collection(db, "users", owner?.uid, "notification"), {
  //       createdAt: serverTimestamp(),
  //       content: "拠点参加希望",
  //       fromAvatar: user?.avatar,
  //       fromId: user?.uid,
  //       fromName: user?.lastName + user?.firstName,
  //       isRecognition: false,
  //       target: "base",
  //       targetId: requestId,
  //     });

  //     displayNotification({
  //       message: "拠点参参加申請受け付けました。",
  //       type: "success",
  //     });
  //     setOpen(false);
  //     window.location.reload();
  //   } catch (err) {
  //     setLoading(false);
  //     setError(err);
  //   }
  // };


  // 複数村長処理 20250306
  const handleDelete = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await setDoc(
        doc(db, "base", requestId, "members", auth.currentUser.uid),
        {
          isMember: false,
          isOwner: false,
          createdAt: serverTimestamp(),
          uid: auth.currentUser.uid,
          isApplication: true,
        }
      );
  
      // **🔥 全ての村長（owners）に通知**
      const notifications = owners.map((owner) =>
        addDoc(collection(db, "users", owner.id, "notification"), {
          createdAt: serverTimestamp(),
          content: "拠点参加希望",
          fromAvatar: user?.avatar,
          fromId: user?.uid,
          fromName: user?.lastName + user?.firstName,
          isRecognition: false,
          target: "base",
          targetId: requestId,
        })
      );
  
      await Promise.all(notifications); // すべての通知を送信
  
      displayNotification({
        message: "拠点参加申請を送信しました。",
        type: "success",
      });
  
      setOpen(false);
      window.location.reload();
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };
  

  return (
    <>
      <div className="mb-3 bg-blue-500 text-white p-1 rounded-full text-center">
        拠点参加
      </div>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="font-bold text-2xl">拠点に参加申請を送ります</div>
        <div className="border-2 p-2 rounded-md mb-2">
          <div className="text-gray-300">拠点</div>
          <div>{base?.title}</div>
        </div>
        <button
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
          className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
          disabled={loading}
          onClick={handleDelete}
        >
          {loading ? "参加申請中..." : "参加申請する"}
        </button>
        {error ? error : ""}
      </form>
    </>
  );
};

export default AddBase;
